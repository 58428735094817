import { Divider, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  ConfirmModal,
  Heading3,
  TextL,
  TextM,
  TextS,
} from 'app/components';
import { formatCNPJ, formatCPF, formatPhone } from 'app/helpers';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import { useCallback, useRef, useState, useContext, useEffect } from 'react';
import { ListBox } from '../components';
import {
  BorrowerStepContent,
  InfoArea,
  ListBoxItem,
  ListBoxSection,
  QualificationModalContent,
} from './styles';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { StepContentButtonsArea } from '../styles';
import { useNavigate } from 'react-router-dom';
import { useQualificationsService } from 'modules/products/services/hooks';
import { TQualification } from 'modules/customer/interfaces/qualifications';

export type ItemPersonType = TRegisterPersonDataRequest;
export type ItemBusinessType = TRegisterBusinessDataRequest;
export type TBorrowerType = 'PERSON' | 'BUSINESS';

const BorrowerInfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 24px',
      }}
    />
  );
};

const filterItemsPerson = [
  { name: 'Nome', parameterName: 'full_name' },
  { name: 'CPF', parameterName: 'taxpayer_id' },
  { name: 'Email', parameterName: 'email_address' },
];

const filterItemsBusiness = [
  { name: 'Nome', parameterName: 'legal_name' },
  { name: 'CNPJ', parameterName: 'taxpayer_id' },
  { name: 'Email', parameterName: 'email_address' },
];

const BorrowerStep = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { getPersonList, personListLoading } = usePersonService();
  const { getBusinessList, creatBusinessLoading } = useCustomerService();
  const {
    product,
    borrowerData,
    borrowerQualification,
    updateBorrowerQualification,
    updateBorrowerData,
    onBack,
    onForward,
  } = useContext(ApplicationRequestContext);
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = useRef<number>(0);
  const searchParameter = useRef<string>('');
  const searchValue = useRef<string>('');
  const [isQualificationAlertModalOpen, toggleQualificationModalAlertOpen] =
    useState<boolean>(false);
  const [hasMoreItems, updateHasMoreItems] = useState<boolean>(false);
  const { getBorrowerQualification } = useQualificationsService();
  const [personBorrowerList, setPersonBorrowerList] = useState<
    TRegisterPersonDataRequest[]
  >([]);
  const [businessBorrowerList, setBusinessBorrowerList] = useState<
    TRegisterBusinessDataRequest[]
  >([]);
  const borrowerType: TBorrowerType = product?.borrower_type as TBorrowerType;

  useEffect(() => {
    updateBorrowerQualification(null);
    if (borrowerData) {
      if (product?.borrower_type === 'PERSON') {
        setPersonBorrowerList([borrowerData]);
        return;
      }
      setBusinessBorrowerList([borrowerData]);
      return;
    }
  }, []);

  const onForwardRequest = useCallback(() => {
    if (product && borrowerData) {
      getBorrowerQualification(product.id, borrowerData.id as string).then(
        (qualificationData) => {
          const qualification = qualificationData?.content?.[0];
          if (qualification) {
            updateBorrowerQualification(qualification as TQualification);

            if (
              qualification.status !== 'QUALIFIED' ||
              (product.conditions_required && !qualification.condition)
            ) {
              toggleQualificationModalAlertOpen(true);
              return;
            }
          } else if (product.qualification_required) {
            toggleQualificationModalAlertOpen(true);
            return;
          }
          onForward();
        },
      );
    }
  }, [borrowerData, product, onForward]);

  //called by debounce on first render
  const onSearch = (field: string, value: string) => {
    searchParameter.current = field;
    searchValue.current = value;
    currentPage.current = 0;
    setPersonBorrowerList([]);
    setBusinessBorrowerList([]);

    if (product?.borrower_type === 'PERSON') {
      if (field === '') {
        handleGetPerson(0);
        return;
      }
      handleGetPerson(0, field, value);
      return;
    }

    if (product?.borrower_type === 'BUSINESS') {
      if (field === '') {
        handleGetBusiness(0);
        return;
      }
      handleGetBusiness(0, field, value);
    }
  };

  const handleGetPerson = useCallback(
    async (
      page: number,
      searchParameterName?: string,
      searchParameterValue?: string,
    ) => {
      setIsLoading(true);
      const res = await getPersonList(
        page,
        searchParameterName,
        searchParameterValue,
      );
      updateHasMoreItems(!!res?.has_next);
      setPersonBorrowerList((state) =>
        state.concat(
          res?.content.filter((item) => {
            const index = state.findIndex((el) => el.id === item.id);
            return index < 0;
          }) || [],
        ),
      );
      currentPage.current = page;
      setIsLoading(false);
    },
    [currentPage],
  );

  const handleGetBusiness = useCallback(
    async (
      page: number,
      searchParameterName?: string,
      searchParameterValue?: string,
    ) => {
      setIsLoading(true);
      const res = await getBusinessList(
        page,
        searchParameterName,
        searchParameterValue,
      );
      updateHasMoreItems(!!res?.has_next);
      setBusinessBorrowerList((state) =>
        state.concat(
          res?.content.filter((item) => {
            const index = state.findIndex((el) => el.id === item.id);
            return index < 0;
          }) || [],
        ),
      );
      currentPage.current = page;
      setIsLoading(false);
    },
    [currentPage],
  );

  const handleRegister = () => {
    product?.borrower_type === 'PERSON'
      ? navigate('/records/natural/new', {
          state: {
            applicationRequestFlow: true,
            product,
          },
        })
      : navigate('/records/legal/new', {
          state: {
            applicationRequestFlow: true,
            product,
          },
        });
  };

  return (
    <BoxSection boxVariant="rounded">
      <BorrowerStepContent>
        <Heading3>
          Para qual <strong>tomador </strong>é a solicitação?
        </Heading3>
        <ListBox
          hasMoreItems={hasMoreItems}
          filterItems={
            product?.borrower_type === 'PERSON'
              ? filterItemsPerson
              : filterItemsBusiness
          }
          items={
            personBorrowerList.length > 0
              ? personBorrowerList
              : businessBorrowerList
          }
          onSearch={onSearch}
          isLoading={isLoading || personListLoading || creatBusinessLoading}
          handleRequest={
            borrowerType === 'PERSON'
              ? () =>
                  handleGetPerson(
                    currentPage.current + 1,
                    searchParameter.current
                      ? searchParameter.current
                      : undefined,
                    searchValue.current ? searchValue.current : undefined,
                  )
              : () =>
                  handleGetBusiness(
                    currentPage.current + 1,
                    searchParameter.current
                      ? searchParameter.current
                      : undefined,
                    searchValue.current ? searchValue.current : undefined,
                  )
          }
        >
          {(i) => {
            const item = i as ItemPersonType & ItemBusinessType;
            return (
              <ListBoxItem
                className={borrowerData?.id === item.id ? 'selected' : ''}
                onClick={() => updateBorrowerData(item)}
                key={item.taxpayer_id}
              >
                <TextL
                  style={{
                    color:
                      borrowerData?.id === item.taxpayer_id
                        ? theme.palette.brand.primary.base
                        : theme.palette.brand.secondary.base,
                  }}
                  weight="semibold"
                >
                  {borrowerType === 'PERSON' ? item.full_name : item.legal_name}
                </TextL>
                <InfoArea>
                  <TextS>
                    {borrowerType === 'PERSON'
                      ? formatCPF(item.taxpayer_id || '')
                      : formatCNPJ(item.taxpayer_id)}
                  </TextS>
                  <BorrowerInfoDivider />
                  <TextS>
                    {formatPhone(
                      item.phone.area_code + item.phone.number || '',
                    )}
                  </TextS>
                  <BorrowerInfoDivider />
                  <TextS>{item.email_address}</TextS>
                </InfoArea>
              </ListBoxItem>
            );
          }}
        </ListBox>
        <ListBoxSection>
          <TextM weight="Semibold">Não cadastrou o tomador?</TextM>
          <ButtonPrimary style={{ maxWidth: '30%' }} onClick={handleRegister}>
            <AddIcon />
            <TextM
              weight="Semibold"
              style={{ color: theme.palette.common.white }}
            >
              Cadastrar{' '}
              {product?.borrower_type === 'PERSON' ? 'pessoa' : 'empresa'}
            </TextM>
          </ButtonPrimary>
        </ListBoxSection>
        <StepContentButtonsArea>
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
          <ButtonPrimary disabled={!borrowerData} onClick={onForwardRequest}>
            Avançar
          </ButtonPrimary>
        </StepContentButtonsArea>
      </BorrowerStepContent>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isQualificationAlertModalOpen}
        icon={<AttentionCircularIcon />}
        title={
          borrowerQualification?.status === 'QUALIFIED' &&
          product?.conditions_required &&
          !borrowerQualification?.condition
            ? `${
                borrowerType === 'PERSON'
                  ? borrowerData?.full_name
                  : borrowerData?.legal_name
              } não possui condições configuradas.`
            : `${
                borrowerType === 'PERSON'
                  ? borrowerData?.full_name
                  : borrowerData?.legal_name
              } ainda não foi aprovado para este produto.`
        }
        btnConfirmText="Limpar e informar outro tomador"
        handleSkip={!product?.qualification_required ? onForward : undefined}
        btnSkipText="Continuar mesmo assim"
        handleConfirm={() => {
          toggleQualificationModalAlertOpen(false);
          updateBorrowerData(null);
          updateBorrowerQualification(null);
        }}
        handleClose={() => toggleQualificationModalAlertOpen(false)}
        handleCancel={() => toggleQualificationModalAlertOpen(false)}
      >
        <QualificationModalContent>
          <TextM>
            {`O tomador selecionado não possui linha de crédito para o produto `}
            <strong>{product?.name}</strong>
          </TextM>
          <a
            href={
              borrowerType === 'PERSON'
                ? `#/records/natural/${borrowerData?.id}/qualifications`
                : `#/records/legal/${borrowerData?.id}/qualifications`
            }
            target="_blank"
            style={{
              textDecoration: 'underline',
              marginTop: '20px',
              textAlign: 'right',
            }}
            className="link"
            rel="noreferrer"
          >
            Ir para o cadastro
          </a>
        </QualificationModalContent>
      </ConfirmModal>
    </BoxSection>
  );
};

export default BorrowerStep;
