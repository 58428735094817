/* eslint-disable @typescript-eslint/no-explicit-any */
import { TCustomValue } from 'app/components/CustomSelectModal/CustomSelectModal';
import * as yup from 'yup';

export const editConditionValidation = yup.object({
  creditLimit: yup.string(),
  maxPaymentAmount: yup.string(),
  interestRate: yup.string(),
  minRequestedAmount: yup.string(),
  minGracePeriod: yup.string(),
  maxGracePeriod: yup.string(),
  dueDay: yup.string(),
  minPaymentNumber: yup.string(),
  maxPaymentNumber: yup.string(),
  tac: yup.string(),
  rating: yup
    .object({
      label: yup.string().required('Insira um valor'),
      value: yup.string(),
    })
    .typeError('Insira um valor')
    .test({
      name: 'rating field',
      test: (value: unknown, { createError }: { createError: any }) => {
        return Object.keys(value as TCustomValue).length === 0 ||
          Object.values(value as TCustomValue).find(
            (item) => item === 'Selecionar',
          )
          ? createError({
              message: 'Insira um valor',
              path: 'rating',
            })
          : true;
      },
    }),
});
