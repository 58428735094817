import { Divider, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  EditButton,
  Heading3,
  TextL,
  TextM,
  TextS,
} from 'app/components';
import { useCallback, useState, useContext } from 'react';
import {
  InfoArea,
  SubscriberListContent,
  SubscribersStepContainer,
  SubscribersStepContent,
  SubscribersStepHeader,
  SubscribersStepTableHeadFile,
  SubscribersStepTableWrapper,
} from './styles';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { StepContentButtonsArea } from '../styles';
import { ChevronIcon, EmptyIcon } from 'app/components/Icons';
import SubscribersAddNewSideSheet, {
  ESubscriberType,
  TSubscriber,
} from './SubscribersAddNewSideSheet';
import { formatCPF, formatPhone } from 'app/helpers';
import SubscribersEditSideSheet from './SubscribersEditSideSheet';
import { Table } from 'celcoin-design-system';

export const SubscriberInfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 16px',
      }}
    />
  );
};

export const SubscriberName = {
  [ESubscriberType.COSIGNERS]: 'Anuente',
  [ESubscriberType.GUARANTORS]: 'Avalista',
};

const SubscribersStep = () => {
  const theme = useTheme();
  const [isEditSubscriberOpen, toggleIsEditSubscriberOpen] =
    useState<TSubscriber | null>(null);
  const [isAddNewSubscriberOpen, toggleIsAddNewSubscriberOpen] =
    useState(false);
  const { onBack, onForward, subscribers } = useContext(
    ApplicationRequestContext,
  );

  const handleAddNewSubscriber = useCallback(() => {
    toggleIsAddNewSubscriberOpen(true);
  }, []);
  return (
    <BoxSection boxVariant="rounded">
      <SubscribersStepContent>
        <SubscribersStepHeader>
          <Heading3>Anuentes e Avalistas</Heading3>
          <ButtonPrimary
            style={{ maxWidth: '30%' }}
            typeVariant="outline"
            onClick={handleAddNewSubscriber}
          >
            <AddIcon />
            <TextM
              weight="Semibold"
              style={{ color: theme.palette.brand.primary.base }}
            >
              Adicionar na lista
            </TextM>
          </ButtonPrimary>
        </SubscribersStepHeader>
        <SubscribersStepContainer>
          {!subscribers || subscribers.length === 0 ? (
            <EmptyIcon />
          ) : (
            <SubscribersStepTableWrapper>
              <Table
                tableHeadCell={[
                  {
                    id: '0',
                    content: (
                      <SubscribersStepTableHeadFile>
                        <TextM>Pessoa</TextM>
                        <ChevronIcon />
                      </SubscribersStepTableHeadFile>
                    ),
                  },
                  {
                    id: '1',
                    content: <TextM>Função</TextM>,
                  },
                  {
                    id: '1',
                    content: <TextM>Editar</TextM>,
                  },
                ]}
                tableBodyContent={subscribers.map((item) => {
                  const { subscriber, subscriberType } = item;
                  return {
                    id: '0',
                    cells: [
                      {
                        id: '0',
                        content: (
                          <SubscriberListContent>
                            <TextL weight="semibold">
                              {/* {subscriberPersonType === 'PERSON'
                                ? subscriber.full_name
                                : subscriber.legal_name} */}
                              {subscriber?.full_name}
                            </TextL>
                            <InfoArea>
                              <TextS>
                                {/* {subscriberPersonType === 'PERSON'
                                  ? formatCPF(subscriber.taxpayer_id || '')
                                  : formatCNPJ(subscriber.taxpayer_id)} */}
                                {formatCPF(subscriber?.taxpayer_id || '')}
                              </TextS>
                              <SubscriberInfoDivider />
                              <TextS>
                                {formatPhone(
                                  subscriber.phone.area_code +
                                    subscriber.phone.number || '',
                                )}
                              </TextS>
                            </InfoArea>
                            <TextS>{subscriber.email_address}</TextS>
                          </SubscriberListContent>
                        ),
                      },
                      {
                        id: '1',
                        content: (
                          <TextL>{SubscriberName[subscriberType]}</TextL>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <EditButton
                            handleClick={() => toggleIsEditSubscriberOpen(item)}
                          />
                        ),
                      },
                    ],
                  };
                })}
              />
            </SubscribersStepTableWrapper>
          )}
        </SubscribersStepContainer>
        <StepContentButtonsArea>
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
          <ButtonPrimary onClick={onForward}>Avançar</ButtonPrimary>
        </StepContentButtonsArea>
      </SubscribersStepContent>
      <SubscribersAddNewSideSheet
        isOpen={isAddNewSubscriberOpen}
        handleClose={() => toggleIsAddNewSubscriberOpen(false)}
      />
      <SubscribersEditSideSheet
        isOpen={!!isEditSubscriberOpen}
        handleClose={() => toggleIsEditSubscriberOpen(null)}
        subscriberItem={(isEditSubscriberOpen || {}) as TSubscriber}
      />
    </BoxSection>
  );
};

export default SubscribersStep;
