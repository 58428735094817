import { useTheme } from '@mui/material';
import {
  ButtonPrimary,
  CustomTableSelect,
  Heading3,
  TextM,
} from 'app/components';
import { ArrowIcon, HyperlinkIcon } from 'app/components/Icons';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { formatCurrency } from 'app/utils/normalizer';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EApplicationStatus,
  EApplicationStatusDescription,
} from 'modules/products/services/hooks/useApplicationsService';
import {
  ApplicationDetailsHeader,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from '../../styles';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useAuthContext, usePermify } from 'modules/auth/context';
import { TApplicationData } from '../../../LegalPersonViewApplicationTab/LegalPersonViewApplicationTab';
import { EQualificationStatus } from 'modules/products/context/QualificationsProvider/qualifications.interfaces';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { handleStatusListPermissions } from 'modules/products/helpers/statusList';

const buttonStyle = {
  padding: '11px 15px',
  width: 'fit-content',
};

export interface IConditionContent {
  applicationData: TApplicationData | null;
  isStatusOptionsLoading: boolean;
  handleUpdateQualificationStatus: (
    application: TApplicationData | null,
    identifier: string,
  ) => void;
}

const ConditionContent = ({
  applicationData,
  isStatusOptionsLoading,
  handleUpdateQualificationStatus,
}: IConditionContent) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isProfileAuthorized } = usePermify();
  const { userInfo } = useAuthContext();
  const { showSnackbarCopyLink } = useSnackbar();
  const handleNavigate = () => {
    navigate(`/products/application/${applicationData?.id}/details`);
  };

  const handleClipboard = useCallback(() => {
    navigator.clipboard.writeText(
      `${document.location.origin}/#/products/application/${applicationData?.id}/details`,
    );
    showSnackbarCopyLink();
  }, []);

  const statusList = useMemo(() => {
    if (!applicationData) return [];

    //eslint-disable-next-line
    const { statusApproval, productData, qualificationData, ...application } =
      applicationData;
    if (applicationData.status === EApplicationStatus.PENDING_QUALIFICATION) {
      return [
        {
          label: 'Aprovado',
          value: EQualificationStatus.APPROVED as string,
        },
      ]
        .concat(
          statusApproval && statusApproval?.length > 0
            ? statusApproval.map((status) => ({
                label: status.description,
                value: status.identifier,
              }))
            : [],
        )
        .concat({
          label: 'Negado',
          value: EQualificationStatus.DENIED as string,
        });
    } else if (applicationData.status === EApplicationStatus.ISSUED) {
      return [
        {
          label: EApplicationStatusDescription[EApplicationStatus.ISSUED],
          value: EApplicationStatus.ISSUED,
        },
      ];
    } else if (
      applicationData.status === EApplicationStatus.PENDING_DISBURSEMENT
    ) {
      return [
        {
          label:
            EApplicationStatusDescription[
              EApplicationStatus.PENDING_DISBURSEMENT
            ],
          value: EApplicationStatus.PENDING_DISBURSEMENT,
        },
      ];
    } else if (
      applicationData.status === EApplicationStatus.DISBURSEMENT_ATTEMPT_FAILED
    ) {
      return [
        {
          label:
            EApplicationStatusDescription[
              EApplicationStatus.DISBURSEMENT_ATTEMPT_FAILED
            ],
          value: EApplicationStatus.DISBURSEMENT_ATTEMPT_FAILED,
        },
      ];
    }
    return [
      {
        label:
          EApplicationStatusDescription[EApplicationStatus.AGREEMENT_RENDERING],
        value: EApplicationStatus.AGREEMENT_RENDERING,
      },
      {
        label:
          EApplicationStatusDescription[
            EApplicationStatus.CREATING_BENEFICIARY_ACCOUNT
          ],
        value: EApplicationStatus.CREATING_BENEFICIARY_ACCOUNT,
      },
      {
        label:
          EApplicationStatusDescription[EApplicationStatus.PENDING_SIGNATURE],
        value: EApplicationStatus.PENDING_SIGNATURE,
      },
      {
        label:
          EApplicationStatusDescription[
            EApplicationStatus.PENDING_QUALIFICATION
          ],
        value: EApplicationStatus.PENDING_QUALIFICATION,
      },
      {
        label:
          EApplicationStatusDescription[EApplicationStatus.PENDING_PAYMENT],
        value: EApplicationStatus.PENDING_PAYMENT,
      },
      {
        label: EApplicationStatusDescription[EApplicationStatus.KYC_PROCESSING],
        value: EApplicationStatus.KYC_PROCESSING,
      },
      {
        label: EApplicationStatusDescription[EApplicationStatus.ISSUED],
        value: EApplicationStatus.ISSUED,
      },
      {
        label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
        value: EApplicationStatus.CANCELED,
      },
    ]
      .filter((status) =>
        [applicationData.status, EApplicationStatus.CANCELED].includes(
          status.value,
        ),
      )
      .filter(
        handleStatusListPermissions(
          application,
          application?.status === EApplicationStatus.PENDING_QUALIFICATION
            ? qualificationData?.status
            : application?.status,
          userInfo,
        ),
      );
  }, [applicationData, userInfo]);

  return (
    <>
      <ApplicationDetailsHeader>
        <Heading3>Solicitação</Heading3>

        <div style={{ display: 'flex', gap: '6px' }}>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleClipboard}
          >
            <HyperlinkIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleNavigate}
          >
            <TextM
              weight="Semibold"
              style={{
                color: theme.palette.brand.primary.base,
                marginRight: '8px',
              }}
            >
              Ver página
            </TextM>
            <ArrowIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
        </div>
      </ApplicationDetailsHeader>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Status</TextM>
            </span>
            <span>
              <CustomTableSelect
                selectItems={statusList}
                isLoading={isStatusOptionsLoading}
                disabled={
                  !isProfileAuthorized(
                    EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
                  ) || !applicationData?.productData
                }
                handleChange={(identifier) =>
                  handleUpdateQualificationStatus(applicationData, identifier)
                }
                value={
                  (applicationData?.status ===
                  EApplicationStatus.PENDING_QUALIFICATION
                    ? applicationData?.qualificationData?.status
                    : applicationData?.status) || ''
                }
              />
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Valor solicitado</TextM>
            </span>
            <span>
              <TextM>{formatCurrency(applicationData?.requested_amount)}</TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Valor da parcela</TextM>
            </span>
            <span>
              <TextM>
                {formatCurrency(applicationData?.loan_details?.payment_amount)}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Valor Financiado</TextM>
            </span>
            <span>
              <TextM>
                {formatCurrency(applicationData?.loan_details?.financed_amount)}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Prazo</TextM>
            </span>
            <span>
              <TextM>
                {applicationData?.num_payments}{' '}
                {applicationData?.num_payments === 1 ? 'mês' : 'meses'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Criado em</TextM>
            </span>
            <span>
              <TextM>
                {formatDateFromBackend(
                  applicationData?.created_at || '',
                ).toLocaleDateString()}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Vencimento da primeira parcela</TextM>
            </span>
            <span>
              <TextM>
                {formatDateFromBackend(
                  applicationData?.first_payment_date || '',
                ).toLocaleDateString()}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
      </ViewBoxContainer>
    </>
  );
};

export default ConditionContent;
