import { useState } from 'react';
import { useSnackbar } from '../../../../app/hooks/useSnackbar';
import {
  TProducts,
  qualificationsActionType,
  useQualificationsContext,
} from '../../context';
import {
  TBorrowerQualification,
  TQualification,
} from '../../context/QualificationsProvider/qualifications.interfaces';
import { QualificationsHttpService } from '../api';
import {
  IConditionUpdate,
  IList,
  TBorrowerQualificationRequestType,
} from './intefaces';
import { TQualificationRequestApplication } from './useApplicationsService';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import useProductsService from './useProductsService';
import { useUploadBusinessFiles } from 'modules/customer/hooks';
import { CustomerHttpService } from 'modules/customer/services';
import get from 'lodash.get';

export type TQualificationPersonRole = 'BORROWER' | 'EMPLOYER' | 'APPLICATION';

const useQualificationsService = () => {
  const { dispatch } = useQualificationsContext();
  const { showSnackbar } = useSnackbar();
  const { getProduct, getCustomVariables } = useProductsService();
  const { fetchBusinessFile } = useUploadBusinessFiles();

  const [getProductQualificationsLoading, setGetProductQualificationsLoading] =
    useState(false);
  const [
    getQualificationStatusOptionsLoading,
    setGetQualificationStatusOptionsLoading,
  ] = useState(false);
  const [
    updateQualificationStatusLoading,
    setUpdateQualificationStatusLoading,
  ] = useState<string | false>(false);
  const [
    updateQualificationConditionLoading,
    setUpdateQualificationConditionLoading,
  ] = useState(false);

  const getProductQualifications = async (
    productId: string,
    role: TQualificationPersonRole = 'EMPLOYER',
    page: number,
    linesPerPage = 10,
  ): Promise<TProducts | void> => {
    setGetProductQualificationsLoading(true);
    try {
      const productDataRes = await getProduct(productId);
      const customerHttpService = new CustomerHttpService();
      const customVariables = await getCustomVariables(productId);
      const qualificationsService = new QualificationsHttpService();
      const result = await qualificationsService.getProductQualifications(
        productId,
        role,
        page,
        linesPerPage,
      );

      const content = await Promise.all<TQualification[]>(
        result.data.content.map(async (qualification: TQualification) => {
          const businessDetail = await customerHttpService.getBusiness(
            qualification.employer.id,
          );
          const businessUploadedFiles = await fetchBusinessFile(
            qualification.employer.id,
          );
          const missingDocuments =
            (
              productDataRes?.data as TProducts
            ).variables_configuration?.employer_document.filter((document) => {
              if (document.required) {
                const inx = businessUploadedFiles.findIndex(
                  (businessUploadedFile) =>
                    document.type === businessUploadedFile.type,
                );
                return inx === -1;
              }
              return false;
            }) || [];

          const missingVariables =
            customVariables?.employer.filter((variable) => {
              if (variable.required) {
                const variableValue = get(businessDetail, variable.name);
                return typeof variableValue === 'undefined';
              }
              return false;
            }) || [];

          return {
            ...qualification,
            missingDocuments,
            missingVariables,
          };
        }),
      );

      dispatch({
        type: qualificationsActionType.SET_QUALIFICATIONS,
        payload: {
          content,
          hasMorePage: result.data.has_next,
          page,
          totalElements: result.data.total_elements,
        },
      });

      return productDataRes?.data;
    } catch (error) {
      console.log(error);
      showSnackbar('Ocorreu um erro ao buscar lista de linhas de crédito');
    } finally {
      setGetProductQualificationsLoading(false);
    }
  };

  const getQualificationStatusOptions = async (
    productId: string,
    productType: 'PERSON' | 'BUSINESS' = 'BUSINESS',
  ) => {
    setGetQualificationStatusOptionsLoading(true);
    try {
      const qualificationsService = new QualificationsHttpService();
      const result = await qualificationsService.getQualificationStatusOptions(
        productId,
      );
      if (productType === 'BUSINESS') {
        dispatch({
          type: qualificationsActionType.SET_STATUS_OPTIONS,
          payload: Array.isArray(result.data?.employer_onboarding)
            ? result.data?.employer_onboarding
            : [],
        });
        return result.data?.employer_onboarding || [];
      }

      if (productType === 'PERSON') {
        dispatch({
          type: qualificationsActionType.SET_STATUS_OPTIONS,
          payload: Array.isArray(result.data?.borrower_onboarding)
            ? result.data?.borrower_onboarding
            : [],
        });

        return result.data?.borrower_onboarding || [];
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetQualificationStatusOptionsLoading(false);
    }
  };

  const getPersonQualificationStatusOptions = async (productId: string) => {
    setGetQualificationStatusOptionsLoading(true);
    try {
      const qualificationsService = new QualificationsHttpService();
      const result = await qualificationsService.getQualificationStatusOptions(
        productId,
      );
      dispatch({
        type: qualificationsActionType.SET_STATUS_OPTIONS_PERSON,
        payload: Array.isArray(result.data?.borrower_onboarding)
          ? result.data?.borrower_onboarding
          : [],
      });
    } catch (error) {
      console.log(error);
    } finally {
      setGetQualificationStatusOptionsLoading(false);
    }
  };

  const updateQualificationStatus = async (
    productId: string,
    qualificationId: string,
    identifier: string,
    qualificationPersonRole: TQualificationPersonRole = 'EMPLOYER',
  ) => {
    setUpdateQualificationStatusLoading(qualificationId);
    try {
      const qualificationsService = new QualificationsHttpService();
      const res = await qualificationsService.updateQualificationStatus(
        productId,
        qualificationId,
        { status: identifier },
      );
      if (qualificationPersonRole === 'EMPLOYER') {
        dispatch({
          type: qualificationsActionType.UPDATE_QUALIFICATION,
          payload: {
            id: qualificationId,
            qualification: {
              status: identifier,
            },
          },
        });
      }

      if (qualificationPersonRole === 'BORROWER') {
        dispatch({
          type: qualificationsActionType.UPDATE_PERSON_QUALIFICATION,
          payload: {
            id: qualificationId,
            qualification: {
              status: identifier,
            },
          },
        });
      }

      return res;
    } catch (error) {
      console.log(error);
      showSnackbar('Ocorreu um erro ao atualizar o status');
    } finally {
      setUpdateQualificationStatusLoading(false);
    }
  };

  const getProductQualificationsPerson = async (
    productId: string,
    page = 0,
    itemsPerPage?: number,
  ): Promise<IList<TBorrowerQualification> | undefined> => {
    setGetProductQualificationsLoading(true);
    try {
      const qualificationsService = new QualificationsHttpService();
      const result = await qualificationsService.getBorrowerQualifications(
        productId,
        page,
        itemsPerPage || 10,
      );

      return result.data;
    } catch (error) {
      console.log(error);
      showSnackbar('Ocorreu um erro ao buscar lista de pessoas');
    } finally {
      setGetProductQualificationsLoading(false);
    }
  };

  const getBorrowerQualification = async (
    productId: string,
    borrowerId: string,
    borrowerType = EFunctions.BORROWER,
  ): Promise<IList<TBorrowerQualificationRequestType> | undefined> => {
    try {
      const qualificationsService = new QualificationsHttpService();
      if (borrowerType === EFunctions.EMPLOYER) {
        const res = await qualificationsService.getEmployerQualification(
          productId,
          borrowerId,
        );
        return res.data;
      }

      const res = await qualificationsService.getBorrowerQualification(
        productId,
        borrowerId,
      );
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados do tomador');
    }
  };

  const getEmployerQualification = async (
    productId: string,
    employerId: string,
  ): Promise<IList<TBorrowerQualificationRequestType> | undefined> => {
    try {
      const qualificationsService = new QualificationsHttpService();
      const res = await qualificationsService.getEmployerQualification(
        productId,
        employerId,
      );
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados do empregador');
    }
  };

  const getBorrowerQualificationApplication = async (
    productId: string,
    borrowerId: string,
  ): Promise<IList<TQualificationRequestApplication> | undefined> => {
    try {
      const qualificationsService = new QualificationsHttpService();
      const res =
        await qualificationsService.getBorrowerQualificationApplication(
          productId,
          borrowerId,
        );
      return res.data;
    } catch (error) {
      showSnackbar(
        'Ocorreu um erro ao buscar os dados da aplicação do tomador',
      );
    }
  };

  const updateQualificationCondition = async (
    productId: string,
    borrowerId: string,
    data: Partial<IConditionUpdate>,
  ) => {
    setUpdateQualificationConditionLoading(true);
    try {
      const qualificationsService = new QualificationsHttpService();
      const res =
        await qualificationsService.updateQualificationRequestCondition(
          productId,
          borrowerId,
          data,
        );
      return res.data;
    } catch (error) {
      showSnackbar(
        'Ocorreu um erro ao atualizar dados das condições do tomador',
      );
    } finally {
      setUpdateQualificationConditionLoading(false);
    }
    return false;
  };

  return {
    getProductQualificationsLoading,
    getQualificationStatusOptionsLoading,
    updateQualificationStatusLoading,
    updateQualificationConditionLoading,
    getProductQualifications,
    getBorrowerQualification,
    getEmployerQualification,
    getProductQualificationsPerson,
    getQualificationStatusOptions,
    getPersonQualificationStatusOptions,
    updateQualificationStatus,
    getBorrowerQualificationApplication,
    updateQualificationCondition,
  };
};

export default useQualificationsService;
