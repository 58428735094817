import { useTheme } from '@mui/material';
import { routingPath } from 'app/routes';
import { useAuthContext, usePermify } from 'modules/auth/context';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  drawerActionType,
  useDrawerContext,
} from '../../context/DrawerProvider';
import { CreditSimulatorModal } from '../CreditSimulator/CreditSimulatorModal';
import { FButtons } from '../FButtons';
import { Header } from '../Header';
import { Celcoin, CelcoinSymbol } from '../Icons/svgs';
import {
  Container,
  Wrapper,
  DashboardContent,
  Content,
  SideMenuContent,
  ImageArea,
  ButtonArea,
  SideMenuDrawerItemContent,
  SideMenuDrawerItemContentText,
} from './styles';
import {
  SideMenuDrawer,
  SideMenuDrawerItem,
  TextL,
} from 'celcoin-design-system';
import { useAppContext } from 'app/context/AppContextProvider';
import {
  AccessManagementIcon,
  BoxIcon,
  DisbursementIcon,
  PersonBusinessIcon,
} from '../Icons';
import { GoogleTagManager } from 'app/services/gtm';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import CessionIcon from '../Icons/CessionIcon';

const DashboardLayout = () => {
  const { state, dispatch } = useDrawerContext();
  const { logo, feature_flags } = useAppContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const { userInfo, isLogged } = useAuthContext();
  const [isSimulatorVisible, setIsSimulatorVisible] = useState(false);
  const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('');
  const { pathname } = useLocation();
  const { isProfileAuthorized } = usePermify();
  const params = useParams();

  useEffect(() => {
    if (!isLogged) {
      navigate(routingPath.auth.login);
    }
  }, [isLogged]);

  useEffect(() => {
    const newPathname = Object.keys(params).reduce((cleanPathname, param) => {
      return cleanPathname.replace(params[param] as string, `:${param}`);
    }, pathname);

    GoogleTagManager.push({
      event: 'pageview',
      page: {
        path: newPathname,
      },
    });
  }, [pathname]);

  const appLogo = useMemo(() => {
    if (logo && typeof logo.location === 'string')
      return `https://s3.sa-east-1.amazonaws.com/public.static.flowfinance.com.br/${logo.location}`;
    return isSideMenuCollapsed ? CelcoinSymbol : Celcoin;
  }, [isSideMenuCollapsed, logo]);

  const handleOpenSimulator = () => {
    setIsSimulatorVisible((prevState) => !prevState);
  };

  const handleCloseSimulator = () => {
    setIsSimulatorVisible(false);
  };

  const handleSelectMenu = (title: string, multiple = false) => {
    if (selectedMenu === title) {
      setSelectedMenu('');
      return;
    }
    setSelectedMenu(title);
    if (multiple) {
      setIsSideMenuCollapsed(false);
    }
  };

  const handleCollapse = () => {
    setSelectedMenu('');
    setIsSideMenuCollapsed((value) => !value);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  if (!isLogged) {
    return null;
  }

  return (
    <Container>
      <Wrapper
        activeOverlay={isSimulatorVisible}
        onClick={handleCloseSimulator}
      >
        <SideMenuDrawer
          collapsed={isSideMenuCollapsed}
          isOpen={state.isOpen}
          handleClose={() => {
            dispatch({ type: drawerActionType.CLOSE_DRAWER });
          }}
          hideOnSmallScreen
        >
          <SideMenuContent>
            <ImageArea onClick={handleCollapse}>
              <img src={appLogo} style={{ maxWidth: '170px' }} alt="logo" />
            </ImageArea>
            <ButtonArea>
              <SideMenuDrawerItem
                title="Produtos"
                icon={<BoxIcon color="white" />}
                hoverIcon={
                  <BoxIcon color={theme.palette.brand.primary.base as string} />
                }
                handleClick={() => {
                  setSelectedMenu('');
                  handleNavigate('/products/main');
                }}
                selected={pathname.includes('/products/')}
                isCollapsed={isSideMenuCollapsed}
              />
              <SideMenuDrawerItem
                title="Cadastros"
                icon={<PersonBusinessIcon color="white" />}
                hoverIcon={
                  <PersonBusinessIcon
                    color={theme.palette.brand.primary.base as string}
                  />
                }
                handleClick={() => handleSelectMenu('records', true)}
                selected={
                  pathname.includes('/records/') || selectedMenu === 'records'
                }
                isOpen={selectedMenu === 'records'}
                isCollapsed={isSideMenuCollapsed}
                multiple
              >
                <SideMenuDrawerItemContent>
                  <SideMenuDrawerItemContentText
                    isSelected={pathname.includes('/records/natural')}
                  >
                    <TextL onClick={() => handleNavigate('/records/natural')}>
                      Pessoas
                    </TextL>
                  </SideMenuDrawerItemContentText>
                  <SideMenuDrawerItemContentText
                    isSelected={pathname.includes('/records/legal')}
                  >
                    <TextL onClick={() => handleNavigate('/records/legal')}>
                      Empresas
                    </TextL>
                  </SideMenuDrawerItemContentText>
                </SideMenuDrawerItemContent>
              </SideMenuDrawerItem>
              {isProfileAuthorized(EAccountPermissions.DISBURSEMENT_ADMIN) && (
                <SideMenuDrawerItem
                  title="Desembolsos"
                  icon={<DisbursementIcon color="white" />}
                  hoverIcon={
                    <DisbursementIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                  }
                  handleClick={() => handleSelectMenu('disbursement', true)}
                  selected={
                    pathname.includes('/disbursement/') ||
                    selectedMenu === 'disbursement'
                  }
                  isOpen={selectedMenu === 'disbursement'}
                  isCollapsed={isSideMenuCollapsed}
                  multiple
                >
                  <SideMenuDrawerItemContent>
                    <SideMenuDrawerItemContentText
                      isSelected={pathname.includes('/disbursement/balance')}
                    >
                      <TextL
                        onClick={() =>
                          handleNavigate(
                            `/disbursement/${userInfo?.fundings[0]}/balance`,
                          )
                        }
                      >
                        Saldo e Conciliação
                      </TextL>
                    </SideMenuDrawerItemContentText>
                  </SideMenuDrawerItemContent>
                  <SideMenuDrawerItemContent>
                    <SideMenuDrawerItemContentText
                      isSelected={
                        pathname.match(/\/disbursement\/[^\\/]+\/statement/) !==
                        null
                      }
                    >
                      <TextL
                        onClick={() =>
                          handleNavigate(
                            `/disbursement/${userInfo?.fundings[0]}/statement`,
                          )
                        }
                      >
                        Extrato
                      </TextL>
                    </SideMenuDrawerItemContentText>
                  </SideMenuDrawerItemContent>
                </SideMenuDrawerItem>
              )}
              {feature_flags?.PORTAL_CESSION_SECTION_ENABLED?.value ===
                'true' && (
                <SideMenuDrawerItem
                  title="Cessão"
                  icon={<CessionIcon color="white" />}
                  hoverIcon={
                    <CessionIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                  }
                  handleClick={() => handleSelectMenu('cession', true)}
                  selected={
                    pathname.includes('/cession/') || selectedMenu === 'cession'
                  }
                  isOpen={selectedMenu === 'cession'}
                  isCollapsed={isSideMenuCollapsed}
                  multiple
                >
                  {isProfileAuthorized(EAccountPermissions.CNAB) && (
                    <SideMenuDrawerItemContent>
                      <SideMenuDrawerItemContentText
                        isSelected={
                          pathname.match(/\/cession\/[^\\/]+\/cnab444/) !== null
                        }
                      >
                        <TextL
                          onClick={() =>
                            handleNavigate(
                              `/cession/${userInfo?.fundings[0]}/cnab444`,
                            )
                          }
                        >
                          CNAB 444
                        </TextL>
                      </SideMenuDrawerItemContentText>
                    </SideMenuDrawerItemContent>
                  )}
                  {/* <SideMenuDrawerItemContent>
                    <SideMenuDrawerItemContentText
                      isSelected={
                        pathname.match(/\/cession\/[^\\/]+\/cessions/) !== null
                      }
                    >
                      <TextL
                        onClick={() =>
                          handleNavigate(
                            `/cession/${userInfo?.fundings[0]}/cessions`,
                          )
                        }
                      >
                        Cessões
                      </TextL>
                    </SideMenuDrawerItemContentText>
                  </SideMenuDrawerItemContent> */}
                </SideMenuDrawerItem>
              )}
              {isProfileAuthorized(EAccountPermissions.USER_ADMIN) && (
                <SideMenuDrawerItem
                  title="Gestão de Acessos"
                  icon={<AccessManagementIcon color="white" />}
                  hoverIcon={
                    <AccessManagementIcon
                      color={theme.palette.brand.primary.base as string}
                    />
                  }
                  handleClick={() => {
                    setSelectedMenu('');
                    handleNavigate('/access-management');
                  }}
                  selected={pathname.includes('/access-management')}
                  isCollapsed={isSideMenuCollapsed}
                />
              )}
            </ButtonArea>
          </SideMenuContent>
        </SideMenuDrawer>
        <Content isCollapsed={isSideMenuCollapsed}>
          <Header />
          <DashboardContent>{<Outlet />}</DashboardContent>
        </Content>
      </Wrapper>

      <FButtons handleClickSimulator={handleOpenSimulator} />
      <CreditSimulatorModal
        handleClose={handleCloseSimulator}
        isActive={isSimulatorVisible}
      />
    </Container>
  );
};

export default DashboardLayout;
