/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseHttpService } from '../../../../app/services';
import { IConditionUpdate } from '../hooks/intefaces';

export type TgetProductQualifications = 'EMPLOYER' | 'BORROWER' | 'APPLICATION';

export class QualificationsHttpService extends BaseHttpService {
  baseUrl = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  getQualificationStatusOptions = async (productId: string) => {
    const res = await this.get(
      `${this.baseUrl}/issuer/products/${productId}/statuses-definitions`,
    );
    return res;
  };

  getProductQualifications = async (
    productId: string,
    role: TgetProductQualifications = 'EMPLOYER',
    page = 0,
    linesPerPage = 10,
  ) => {
    const res = await this.get(
      `${
        this.baseUrl
      }/originator/products/${productId}/qualification-requests?role=${role}&page=${page}&size=${
        linesPerPage || 10
      }`,
    );
    return res;
  };

  updateQualificationStatus = async (
    productId: string,
    qualificationId: string,
    status: { status: string },
  ) => {
    const res = await this.put(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests/${qualificationId}/qualify`,
      status,
    );
    return res;
  };

  getBorrowerQualifications = async (
    productId: string,
    page = 0,
    itemsPerPage: number,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?role=BORROWER&page=${page}&size=${itemsPerPage}`,
    );

    return res;
  };

  getBorrowerQualification = async (
    productId: string,
    borrowerId: string,
    page = 0,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?role=BORROWER&borrower_id=${borrowerId}&page=${page}&size=10`,
    );

    return res;
  };

  getEmployerQualification = async (
    productId: string,
    employerId: string,
    page = 0,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?role=EMPLOYER&employer_id=${employerId}&page=${page}&size=10`,
    );

    return res;
  };

  getBorrowerQualificationApplication = async (
    productId: string,
    borrowerId: string,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?role=APPLICATION&borrower_id=${borrowerId}`,
    );

    return res;
  };

  getQualificationByApplication = async (
    productId: string,
    applicationId: string,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?application_id=${applicationId}`,
    );

    return res;
  };

  updateQualificationRequestCondition = async (
    productId: string,
    qualificationId: string,
    data: Partial<IConditionUpdate>,
  ) => {
    const res = await this.put(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests/${qualificationId}/conditions`,
      data,
    );

    return res;
  };
}
