import { TMovementType, TMovementStatus } from '../interfaces/disbursement';

export const MovementTypeLabel = {
  [TMovementType.ENTRYCREDIT]: 'Recebimento TEF',
  [TMovementType.PIXPAYMENTIN]: 'Recebimento Pix',
  [TMovementType.ENTRYDEBIT]: 'Pagamento TEF',
  [TMovementType.PIXPAYMENTOUT]: 'Pagamento Pix',
  [TMovementType.TEDTRANSFERIN]: ' Recebimento TED',
  [TMovementType.TEDTRANSFEROUT]: 'Pagamento TED',
  [TMovementType.PIXREVERSALIN]: 'Estorno Pix',
  [TMovementType.PIXREVERSALOUT]: 'Estorno Pix',
};

export const MovementStatusLabel = {
  [TMovementStatus.ISSUED]: 'Executada',
  [TMovementStatus.CANCELED]: 'Cancelada',
};
