import { TSimulatorParametersData } from 'app/components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { IApplicationCustomVariablesValues } from 'app/interfaces/customVariablesInterfaces';
import { formatToCurrencyFloat } from 'app/utils/normalizer';
import { format } from 'date-fns';
import { TApplicationSignatureParsed } from 'modules/products/pages/ApplicationDetails/components/ApplicationDetailsContent/ApplicationDetailsContent';
import { TSubscriber } from 'modules/products/pages/ApplicationRequest/SubscribersStep/SubscribersAddNewSideSheet';
import { useSimulatorContext } from 'modules/simulation/context';
import { useState } from 'react';
import {
  ApplicationsHttpService,
  TApplicationParametersDataRequest,
} from '../api/applicationsHttpService';
import { IList } from './intefaces';
import { useAppContext } from 'app/context/AppContextProvider';
import { formatInterestRate } from 'modules/products/helpers/interestRate';
import { TBeneficiaryAccount } from 'modules/products/interfaces/beneficiaryAccount';
import { IPixKey } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { TacValueType } from 'modules/products/pages/ApplicationRequest/ApplicationRequest';

export enum EPaymentMethod {
  BOLETO = 'BOLETO',
  PIX = 'PIX',
  TED = 'TED',
  BANK_ACCOUNT_DEDUCTION = 'BANK_ACCOUNT_DEDUCTION',
  PAYROLL_DEDUCTION = 'PAYROLL_DEDUCTION',
  RECEIVABLES = 'RECEIVABLES',
}

export const PaymentMethodDescription = {
  [EPaymentMethod.BOLETO]: 'Boleto',
  [EPaymentMethod.PIX]: 'Pix',
  [EPaymentMethod.TED]: 'TED',
  [EPaymentMethod.BANK_ACCOUNT_DEDUCTION]: 'Débito em conta',
  [EPaymentMethod.PAYROLL_DEDUCTION]: 'Consignação em folha de pagamento',
  [EPaymentMethod.RECEIVABLES]: 'Recebíveis',
};

export enum ESignatureCollectMethod {
  NONE = 'NONE',
  SMS = 'SMS',
  LINK = 'LINK',
  EMAIL = 'EMAIL',
  PHYSICAL = 'PHYSICAL',
  WHATSAPP = 'WHATSAPP',
}

export enum EIssuerDisbursementType {
  BENEFICIARY = 'BENEFICIARY',
  ORIGINATOR = 'ORIGINATOR',
}

export enum EApplicationStatus {
  AGREEMENT_RENDERING = 'AGREEMENT_RENDERING',
  CREATING_BENEFICIARY_ACCOUNT = 'CREATING_BENEFICIARY_ACCOUNT',
  PENDING_SIGNATURE = 'PENDING_SIGNATURE',
  PENDING_QUALIFICATION = 'PENDING_QUALIFICATION',
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSEMENT_ATTEMPT_FAILED = 'DISBURSEMENT_ATTEMPT_FAILED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  KYC_PROCESSING = 'KYC_PROCESSING',
  ISSUED = 'ISSUED',
  CANCELED = 'CANCELED',
  PENDING_RANDOM = 'PENDING_RANDOM',
  PENDING_CASHOUT = 'PENDING_CASHOUT',
  PENDING_INTEGRATION_LAQUS = 'PENDING_INTEGRATION_LAQUS',
  AWAITING_APPROVAL_DISBURSEMENT = 'AWAITING_APPROVAL_DISBURSEMENT',
}

export enum EApplicationStatusDescription {
  AGREEMENT_RENDERING = 'Gerando contrato',
  CREATING_BENEFICIARY_ACCOUNT = 'Criando conta do tomador',
  PENDING_SIGNATURE = 'Pendente de assinatura',
  PENDING_QUALIFICATION = 'Pendente de aprovação',
  PENDING_PAYMENT = 'Pendente de pagamento',
  KYC_PROCESSING = 'Pendente KYC',
  PENDING_DISBURSEMENT = 'Pendente Desembolso',
  DISBURSEMENT_ATTEMPT_FAILED = 'Falha no Pagamento',
  ISSUED = 'Emitido',
  CANCELED = 'Cancelado',
  PENDING_RANDOM = 'Pendente aprovação',
  PENDING_CASHOUT = 'Aguardando Pix',
  PENDING_INTEGRATION_LAQUS = 'Pendente de integração Laqus',
  AWAITING_APPROVAL_DISBURSEMENT = 'Aguardando aprovação de desembolso',
}

export interface TQualificationRequestApplication {
  id: string;
  funding: Funding;
  employer: null;
  role: string;
  status: string;
  borrower: TQualificationRequestApplicationBorrower;
  application: TApplicationResponse;
  created_at: string;
  updated_at: string;
}

export interface ProductClass {
  id: string;
}

export type CustomVariables = unknown;

export interface Funding {
  id: string;
  legal_name: string;
}

export interface LoanDetails {
  total_processing_cost: number;
  annual_interest_rate: number;
  annual_effective_interest_rate: number;
  monthly_effective_interest_rate: number;
  total_amount_owed: number;
  disbursement_amount: number;
  financed_amount: number;
  iof_daily_rate: number;
  iof_amount: number;
  iof_base_rate: number;
  payment_amount: number;
  issue_date: string;
  schedule: Schedule[];
  last_payment_date: string;
}

export interface Schedule {
  payment: number;
  iof: number;
  balance: number;
  interest: number;
  principal: number;
  period: number;
  running_day: number;
  payment_date: string;
}

export interface TQualificationRequestApplicationBorrower {
  id: string;
  name: string;
}

export type ApplicationBeneficiaryAccountBaas = {
  account_number: string;
};

export type ApplicationBeneficiaryAccountExternalBankAccount = {
  bank_code: string;
  bank_account: string;
  bank_account_digit: string;
  bank_branch: string;
  bank_account_type: string;
  ispb_code: string;
};

export type ApplicationBeneficiaryAccountHolder = {
  name: string;
  taxpayer_id: string;
};

export type ApplicationBeneficiaryAccountPix = {
  key: string;
  key_type: string;
};

export interface ApplicationBeneficiaryAccount {
  baas: ApplicationBeneficiaryAccountBaas | null;
  external_bank_account: ApplicationBeneficiaryAccountExternalBankAccount | null;
  holder: ApplicationBeneficiaryAccountHolder;
  pix: IPixKey | null;
  registered_account_id: string | null;
}

export interface PaymentOrder {
  amount: number;
  beneficiary_account: ApplicationBeneficiaryAccount;
  beneficiary_type: string;
  id: string;
  status: string;
  type: string;
}

export type TApplicationResponse = {
  id: string;
  product: { id: string };
  borrower: { id: string };
  requested_amount: number;
  interest_rate: number;
  tac_amount: number;
  finance_fee: number;
  num_payments: number;
  first_payment_date: string;
  disbursement_date: string;
  issue_date: string;
  status: string;
  status_description?: string;
  base_premium_amount?: number;
  issue_value?: number;
  additional_premium_amount?: number;
  managed_account_for_issuer_disbursement?: {
    product_configuration: null;
    baas?: {
      account_number: string;
      pix_key: string;
      holder_type: string;
    } | null;
  } | null;
  loan_details: {
    total_processing_cost: number;
    annual_interest_rate: number;
    annual_effective_interest_rate: number;
    monthly_effective_interest_rate: number;
    total_amount_owed: number;
    disbursement_amount: number;
    financed_amount: number;
    iof_daily_rate: number;
    iof_amount: number;
    iof_base_rate: number;
    payment_amount: number;
    issue_date: string;
    schedule: Array<{
      payment: number;
      iof: number;
      balance: number;
      interest: number;
      principal: number;
      period: number;
      running_day: number;
      payment_date: string;
    }>;
    last_payment_date: string;
  };
  custom_variables: { [key: string]: string | null | undefined };
  canceled_at: string | null;
  sequential_id: number;
  beneficiary_account?:
    | (TBeneficiaryAccount & { registered_account_id: string })
    | null;
  funding?: {
    id?: string;
    legal_name?: string;
    alias?: string;
  };
  payment_method: EPaymentMethod;
  backoffice_import_status: string;
  guarantors: Array<{ guarantor_id: string; type: 'PERSON' | 'BUSINESS' }>;
  co_signers: Array<{ co_signer_id: string; type: 'PERSON' | 'BUSINESS' }>;
  signature_collect_method: ESignatureCollectMethod;
  issuer_disbursement_type: EIssuerDisbursementType;
  disbursement?: {
    processed_at: string;
    receipt: string;
    requested_at: string;
  };
  signature_collect_options: {
    require_self_photo: boolean;
    require_document_photo: boolean;
    require_selfie_validation: boolean;
  };
  loan: null;
  created_at: string;
  payment_orders: PaymentOrder[];
};

const useCreditSimulator = () => {
  const [applicationLoading, setApplicationLoading] = useState(false);
  const [downloadAgreementLoading, setDownloadAgreementLoading] =
    useState(false);
  const [downloadSignedAgreementLoading, setDownloadSignedAgreementLoading] =
    useState(false);
  const [cancelApplicationLoading, setCancelApplicationLoading] =
    useState(false);
  const [
    getQualificationStatusOptionsLoading,
    setGetQualificationStatusOptionsLoading,
  ] = useState(false);
  const [
    downloadSignedEndorsementLoading,
    setDownloadSignedEndorsementLoading,
  ] = useState(false);
  const [
    commercialPaperStatusUpdateLoading,
    setCommercialPaperStatusUpdateLoading,
  ] = useState(false);
  const [getBasePremiumAmountLoading, setGetBasePremiumAmountLoading] =
    useState(false);
  const [updatePaymentOrderLoading, setUpdatePaymentOrderLoading] =
    useState(false);
  const { state: simulatorState } = useSimulatorContext();
  const { feature_flags } = useAppContext();
  const { showSnackbar } = useSnackbar();

  const createApplication = async (
    simulation: TSimulatorParametersData,
    productId: string,
    borrowerId: string,
    fundingId: string,
    customVariables: IApplicationCustomVariablesValues[] | null,
    subscribers: TSubscriber[] | null,
    signatureType: string,
    beneficiaryAccount: TBeneficiaryAccount | null,
    paymentMethod?: 'PIX' | 'TED',
  ): Promise<TApplicationResponse | null> => {
    try {
      setApplicationLoading(true);
      const applicationParametersDataParsed: TApplicationParametersDataRequest =
        {
          amount:
            simulatorState?.applicationPreview?.requested_amount ||
            Number(formatToCurrencyFloat(simulation.amount)),
          numPayments: parseInt(simulation.numPayments),
          ...(simulation.tacType.value === TacValueType.MONETARY
            ? {
                tacAmount: Number(formatToCurrencyFloat(simulation.tacAmount)),
                tacRate: undefined,
              }
            : {
                tacRate: formatInterestRate(simulation.tacRate),
                tacAmount: undefined,
              }),
          interestRate: formatInterestRate(simulation.interestRate) ?? 0,
          financeFee: Number(formatToCurrencyFloat(simulation.financeFee)),
          firstPaymentDate: format(
            new Date(simulation.firstPaymentDate),
            'yyyy-MM-dd',
          ),
          disbursementDate: format(
            new Date(simulation.disbursementDate),
            'yyyy-MM-dd',
          ),
          borrowerId,
          productId,
          beneficiaryAccountId: beneficiaryAccount?.id,
          customVariables,
          subscribers,
          signatureType,
          fundingId,
          paymentMethod,
          signatureCollectOptions: {
            requireSelfPhoto: feature_flags?.PORTAL_SIGNATURE_REQUIRE_SELFIE
              ? feature_flags?.PORTAL_SIGNATURE_REQUIRE_SELFIE?.isActive
              : feature_flags?.feature_flags?.signature_collect_options
                  ?.require_self_photo
              ? feature_flags?.feature_flags?.signature_collect_options
                  ?.require_self_photo
              : false,
            requireSelfieValidation:
              feature_flags?.PORTAL_SIGNATURE_REQUIRE_FACEMATCH
                ? feature_flags?.PORTAL_SIGNATURE_REQUIRE_FACEMATCH?.isActive
                : false,
          },
        };

      const applicationService = new ApplicationsHttpService();
      const res = await applicationService.createApplication(
        applicationParametersDataParsed,
      );
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
      setApplicationLoading(false);
    } finally {
      setApplicationLoading(false);
    }
    return null;
  };

  const getBasePremiumAmount = async (
    financedAmount: number,
    fundingId: string,
  ): Promise<{ amount: number }> => {
    try {
      setGetBasePremiumAmountLoading(true);
      const applicationService = new ApplicationsHttpService();
      applicationService.setHeader({
        handled: true,
      });
      const res = await applicationService.getBasePremiumAmount(
        financedAmount,
        fundingId,
      );
      return res.data;
    } catch (error) {
      setGetBasePremiumAmountLoading(false);
    } finally {
      setGetBasePremiumAmountLoading(false);
    }
    return { amount: 0 };
  };

  const getApplication = async (
    applicationId: string,
  ): Promise<TApplicationResponse | null> => {
    try {
      setApplicationLoading(true);
      const applicationService = new ApplicationsHttpService();
      applicationService.setHeader({
        handled: true,
      });
      const res = await applicationService.getApplication(applicationId);
      return res.data;
    } catch (error) {
      setApplicationLoading(false);
    } finally {
      setApplicationLoading(false);
    }
    return null;
  };

  const getApplications = async (
    page: number,
    size?: number,
    parameters?: { [key: string]: string | null | undefined },
    filterStatusSelect?: string[],
    filterIds?: string[],
  ): Promise<IList<TApplicationResponse> | null> => {
    try {
      setApplicationLoading(true);
      const applicationService = new ApplicationsHttpService();
      const res = await applicationService.getApplications(
        page,
        size,
        parameters,
        filterStatusSelect,
        filterIds,
      );
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
      setApplicationLoading(false);
    } finally {
      setApplicationLoading(false);
    }
    return null;
  };

  const cancelApplication = async (applicationId: string): Promise<boolean> => {
    try {
      setCancelApplicationLoading(true);
      const applicationService = new ApplicationsHttpService();
      const res = await applicationService.cancelApplication(applicationId);
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
      setCancelApplicationLoading(false);
    } finally {
      setCancelApplicationLoading(false);
    }
    return false;
  };

  const getQualificationStatusOptions = async (productId: string) => {
    setGetQualificationStatusOptionsLoading(true);
    try {
      const applicationsHttpService = new ApplicationsHttpService();
      const result = await applicationsHttpService.getApplicationStatusOptions(
        productId,
      );

      return Array.isArray(result.data?.application_approval)
        ? result.data?.application_approval
        : [];
    } catch (error) {
      console.log(error);
    } finally {
      setGetQualificationStatusOptionsLoading(false);
    }
  };

  const getQualificationRequestApplication = async (
    productId: string,
    page: number,
    borrowerId?: string,
    applicationId?: string,
  ): Promise<IList<TQualificationRequestApplication> | undefined> => {
    setApplicationLoading(true);
    try {
      const applicationsHttpService = new ApplicationsHttpService();
      const result =
        await applicationsHttpService.getQualificationRequestApplication(
          productId,
          page,
          borrowerId,
          applicationId,
        );
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      setApplicationLoading(false);
    }
  };

  const getApplicationSignatures = async (
    applicationId: string,
  ): Promise<Array<TApplicationSignatureParsed> | null> => {
    setApplicationLoading(true);
    try {
      const applicationsHttpService = new ApplicationsHttpService();
      const result = await applicationsHttpService.getApplicationSignatures(
        applicationId,
      );

      return result;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
    } finally {
      setApplicationLoading(false);
    }
    return null;
  };

  const getApplicationAgreement = async (applicationId: string) => {
    setDownloadAgreementLoading(true);
    try {
      const applicationsHttpService = new ApplicationsHttpService();
      applicationsHttpService.setRequestConfig({
        responseType: 'arraybuffer',
      });
      const result = await applicationsHttpService.getAgreementFile(
        applicationId,
      );

      return result;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
    } finally {
      setDownloadAgreementLoading(false);
    }
  };

  const getSignedApplicationAgreement = async (applicationId: string) => {
    setDownloadSignedAgreementLoading(true);
    try {
      const applicationsHttpService = new ApplicationsHttpService();
      applicationsHttpService.setRequestConfig({
        responseType: 'arraybuffer',
      });
      const result = await applicationsHttpService.getSignedAgreementFile(
        applicationId,
      );

      return result;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
    } finally {
      setDownloadSignedAgreementLoading(false);
    }
  };

  const getSignedEndorsementFile = async (
    applicationId: string,
  ): Promise<string | null> => {
    setDownloadSignedEndorsementLoading(true);
    try {
      const applicationsHttpService = new ApplicationsHttpService();
      applicationsHttpService.setRequestConfig({
        responseType: 'arraybuffer',
      });
      const result = await applicationsHttpService.getSignedEndorsementFile(
        applicationId,
      );

      return result;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
      return null;
    } finally {
      setDownloadSignedEndorsementLoading(false);
    }
  };

  const updateApplicationPaymentOrder = async (
    applicationId: string,
    paymentOrderId: string,
    data: ApplicationBeneficiaryAccount,
  ) => {
    try {
      setUpdatePaymentOrderLoading(true);
      const applicationsHttpService = new ApplicationsHttpService();
      const res = await applicationsHttpService.updateApplicationPaymentOrder(
        applicationId,
        paymentOrderId,
        data,
      );

      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
    } finally {
      setUpdatePaymentOrderLoading(false);
    }
  };

  const createDisbursementAttempt = async (applicationId: string) => {
    try {
      const applicationsHttpService = new ApplicationsHttpService();
      const res = await applicationsHttpService.createDisbursementAttempt(
        applicationId,
      );

      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
    }
  };

  const commercialPaperStatusUpdate = async (applicationId: string) => {
    try {
      setCommercialPaperStatusUpdateLoading(true);
      const applicationsHttpService = new ApplicationsHttpService();
      const res = await applicationsHttpService.commercialPaperStatusUpdate(
        applicationId,
      );

      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
    } finally {
      setCommercialPaperStatusUpdateLoading(false);
    }
  };

  return {
    createApplication,
    getApplication,
    getApplications,
    cancelApplication,
    getQualificationStatusOptions,
    getQualificationRequestApplication,
    getApplicationSignatures,
    getApplicationAgreement,
    getSignedApplicationAgreement,
    updateApplicationPaymentOrder,
    createDisbursementAttempt,
    getBasePremiumAmount,
    commercialPaperStatusUpdate,
    getSignedEndorsementFile,
    downloadSignedEndorsementLoading,
    commercialPaperStatusUpdateLoading,
    getBasePremiumAmountLoading,
    applicationLoading,
    cancelApplicationLoading,
    getQualificationStatusOptionsLoading,
    downloadAgreementLoading,
    downloadSignedAgreementLoading,
    updatePaymentOrderLoading,
  };
};

export default useCreditSimulator;
