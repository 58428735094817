import { useCallback, useState, useContext, useEffect } from 'react';
import { CustomSelectModal } from 'app/components/CustomSelectModal';
import { CustomCurrencyInput, CustomInput } from 'celcoin-design-system';
import { useFormik } from 'formik';

import { StepContent } from '../../../components';
import {
  ConditionsContent,
  StepContentInput,
  StepContentButtonsArea,
} from './styles';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { ButtonDefault, ButtonPrimary, Spinner } from 'app/components';
import { ArrowBack } from '@mui/icons-material';
import { getNumbersFromValue } from 'app/utils/numbers';
import { formatDecimalField } from 'app/helpers';
import { usePersonContext } from 'modules/customer/context';
import { editConditionValidation } from 'modules/customer/helpers';
import { editConditionValidationRequired } from 'modules/customer/helpers/conditionStepValidation';
import { NaturalPersonHandlerStepperContext } from '../NaturalPersonHandler';
import { usePerson } from 'modules/customer/hooks';
import {
  formatCurrency,
  formatToCurrencyFloat,
  normalizeAddDigits,
} from 'app/utils/normalizer';
import useRatingsService from 'modules/products/services/hooks/useRatingsService';
import { formatInterestRate } from 'modules/products/helpers/interestRate';

export type TReactChangeInput = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

const Conditions = () => {
  const [ratingSelectOpen, setRatingSelectOpen] = useState(false);
  const [ratingsList, setRatingsList] = useState<
    { label: string; value: string }[] | undefined
  >([]);

  const {
    state: { product, registerData },
  } = usePersonContext();
  const { funding, onForward, onBack } = useContext(
    NaturalPersonHandlerStepperContext,
  );
  const { setPersonData } = usePerson();
  const { getRatings: getRatingsRequest, getRatingsLoading } =
    useRatingsService();

  const formik = useFormik({
    initialValues: {
      creditLimit: registerData.qualification_request?.condition?.line_of_credit
        ?.credit_limit
        ? formatCurrency(
            registerData.qualification_request?.condition?.line_of_credit
              ?.credit_limit || 0,
            false,
          ) || ''
        : '',
      maxPaymentAmount: registerData.qualification_request?.condition
        ?.line_of_credit?.max_payment_amount
        ? formatCurrency(
            registerData.qualification_request?.condition?.line_of_credit
              ?.max_payment_amount || 0,
            false,
          ) || ''
        : '',
      minRequestedAmount: registerData.qualification_request?.condition
        ?.min_requested_amount
        ? formatCurrency(
            registerData.qualification_request?.condition
              ?.min_requested_amount || 0,
            false,
          ) || ''
        : '',
      tac: registerData.qualification_request?.condition?.tac
        ? formatCurrency(
            registerData.qualification_request?.condition?.tac || 0,
            false,
          ) || ''
        : '',
      minGracePeriod:
        registerData.qualification_request?.condition?.min_grace_period || '',
      maxGracePeriod:
        registerData.qualification_request?.condition?.max_grace_period || '',
      dueDay: registerData.qualification_request?.condition?.due_day || '',
      minPaymentNumber:
        registerData.qualification_request?.condition?.min_payment_number || '',
      maxPaymentNumber:
        registerData.qualification_request?.condition?.max_payment_number || '',
      interestRate: registerData.qualification_request?.condition
        ?.schedule_based_conditions?.[0]?.interest_rate
        ? normalizeAddDigits(
            registerData.qualification_request?.condition
              ?.schedule_based_conditions?.[0]?.interest_rate * 100,
            2,
          )
        : '',
      rating: {
        label:
          registerData?.qualification_request?.condition?.rating
            ?.display_name || '',
        value:
          registerData?.qualification_request?.condition?.rating?.identifier ||
          '',
      },
    },
    validationSchema: product?.conditions_required
      ? editConditionValidationRequired
      : editConditionValidation,
    enableReinitialize: false,
    onSubmit: async (values) => {
      setPersonData({
        qualification_request: {
          condition: {
            due_day: Number(values.dueDay) || undefined,
            line_of_credit: {
              credit_limit:
                Number(formatToCurrencyFloat(values.creditLimit || '')) ||
                undefined,
              max_payment_amount:
                Number(formatToCurrencyFloat(values.maxPaymentAmount || '')) ||
                undefined,
            },
            min_requested_amount:
              Number(formatToCurrencyFloat(values.minRequestedAmount || '')) ||
              undefined,
            tac: Number(formatToCurrencyFloat(values.tac || '')) || undefined,
            min_grace_period: Number(values.minGracePeriod) || undefined,
            max_grace_period: Number(values.maxGracePeriod) || undefined,
            min_payment_number: Number(values.minPaymentNumber) || undefined,
            max_payment_number: Number(values.maxPaymentNumber) || undefined,
            schedule_based_conditions: [
              {
                interest_rate: formatInterestRate(values.interestRate),
                upper_limit: Number(values.maxPaymentNumber) || undefined,
              },
            ],
            ...(values?.rating?.value?.length > 0 && {
              rating: {
                identifier: values.rating.value,
                display_name: values.rating.label,
              },
            }),
          },
        },
      });
      onForward();
    },
  });

  const handleSubmit = () => {
    const hasAnyFieldFilled = Object.keys(formik.values)
      .filter((field) => field !== 'rating')
      .filter((field) => {
        return !!formik.values[field as keyof typeof formik.values];
      });

    if (!product?.conditions_required && hasAnyFieldFilled.length === 0) {
      onForward();
      return;
    }
    formik.submitForm();
  };

  const getRaitings = async () => {
    const res = await getRatingsRequest(product.id, funding as string);
    const parsedRatings = res?.map((rating) => {
      return {
        label: rating.display_name,
        value: rating.identifier,
      };
    });
    setRatingsList([
      { label: 'Selecionar', value: '' },
      ...(parsedRatings || []),
    ]);
  };

  const handleAmountChange = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue(evt.target.name, '');
      return;
    }
    formik.setFieldValue(
      evt.target.name,
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleIntChange = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue(evt.target.name, '');
      return;
    }
    formik.setFieldValue(
      evt.target.name,
      getNumbersFromValue(evt.target.value || ''),
    );
  }, []);

  const handleInterestRate = useCallback((evt: TReactChangeInput) => {
    if (evt.target.value.length === 0) {
      formik.setFieldValue('interestRate', '');
      return;
    }
    formik.setFieldValue('interestRate', formatDecimalField(evt.target.value));
  }, []);

  useEffect(() => {
    getRaitings();
  }, []);

  return (
    <StepContent
      title="Quais as condições para essa empresa/pessoa?"
      description=""
    >
      <ConditionsContent>
        <StepContentInput>
          <CustomCurrencyInput
            name="creditLimit"
            value={formik.values.creditLimit}
            handleInputChange={handleAmountChange}
            labelValue="Limite Total"
            validationError={
              formik.touched.creditLimit ? formik.errors.creditLimit : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            name="maxPaymentAmount"
            value={formik.values.maxPaymentAmount}
            handleInputChange={handleAmountChange}
            labelValue="Limite de parcela"
            validationError={
              formik.touched.maxPaymentAmount
                ? formik.errors.maxPaymentAmount
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            name="minRequestedAmount"
            value={formik.values.minRequestedAmount}
            handleInputChange={handleAmountChange}
            labelValue="Mínimo solicitação"
            validationError={
              formik.touched.minRequestedAmount
                ? formik.errors.minRequestedAmount
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomCurrencyInput
            name="tac"
            value={formik.values.tac}
            handleInputChange={handleAmountChange}
            labelValue="Tac"
            validationError={formik.touched.tac ? formik.errors.tac : ''}
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="maxGracePeriod"
            value={String(formik.values.maxGracePeriod)}
            handleInputChange={handleIntChange}
            labelValue="Carência máxima"
            validationError={
              formik.touched.maxGracePeriod ? formik.errors.maxGracePeriod : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="minGracePeriod"
            value={String(formik.values.minGracePeriod)}
            handleInputChange={handleIntChange}
            labelValue="Carência mínima"
            validationError={
              formik.touched.minGracePeriod ? formik.errors.minGracePeriod : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="dueDay"
            value={String(formik.values.dueDay)}
            handleInputChange={handleIntChange}
            labelValue="Dia de vencimento"
            validationError={formik.touched.dueDay ? formik.errors.dueDay : ''}
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="minPaymentNumber"
            value={String(formik.values.minPaymentNumber)}
            handleInputChange={handleIntChange}
            labelValue="Prazo mínimo"
            validationError={
              formik.touched.minPaymentNumber
                ? formik.errors.minPaymentNumber
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="maxPaymentNumber"
            value={String(formik.values.maxPaymentNumber)}
            handleInputChange={handleIntChange}
            labelValue="Prazo máximo"
            validationError={
              formik.touched.maxPaymentNumber
                ? formik.errors.maxPaymentNumber
                : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          <CustomInput
            name="interestRate"
            value={formik.values.interestRate}
            handleInputChange={handleInterestRate}
            labelValue="Taxa de juros (% a.m.)"
            validationError={
              formik.touched.interestRate ? formik.errors.interestRate : ''
            }
          />
        </StepContentInput>
        <StepContentInput>
          {getRatingsLoading ? (
            <Spinner />
          ) : (
            <CustomSelectModal
              labelValue="Rating"
              name="rating"
              value={formik.values.rating}
              isSelectOpen={ratingSelectOpen}
              handleSelectChange={(v) => formik.setFieldValue('rating', v)}
              options={ratingsList || [{ label: '', value: '' }]}
              placeholder="Selecionar"
              handleClick={() => setRatingSelectOpen(true)}
              handleSelectClose={() => setRatingSelectOpen(false)}
              validationError={
                formik.touched.rating
                  ? formik.errors.rating?.label || formik.errors.rating?.value
                  : ''
              }
            />
          )}
        </StepContentInput>
      </ConditionsContent>
      <StepContentButtonsArea style={{ gap: '8px' }}>
        <ButtonDefault onClick={onBack}>
          <ArrowBack />
        </ButtonDefault>
        <ButtonPrimary onClick={handleSubmit}>Avançar</ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default Conditions;
