import { useContext } from 'react';
import { BoxSection, Heading3 } from 'app/components';
import { CreditSimulatorComponent } from 'app/components/CreditSimulator/CreditSimulatorComponent';
import { SimulationStepContainer, SimulationStepContent } from './styles';
import { ApplicationRequestContext } from '../ApplicationRequest';

const SimulationStep = () => {
  const {
    product,
    simulation,
    simulationRequest,
    borrowerQualification,
    updateSimulation,
  } = useContext(ApplicationRequestContext);

  return (
    <BoxSection boxVariant="rounded">
      <SimulationStepContent>
        <Heading3>Valores da solicitação</Heading3>
        <SimulationStepContainer>
          {product && (
            <CreditSimulatorComponent
              product={product}
              qualification={borrowerQualification}
              simulationData={simulationRequest}
              simulationPreviewData={simulation}
              mode="COMPONENT"
              onDefineSimulation={(s, sr) => updateSimulation(s, sr)}
            />
          )}
        </SimulationStepContainer>
      </SimulationStepContent>
    </BoxSection>
  );
};

export default SimulationStep;
