import { Divider, useTheme } from '@mui/material';
import { ButtonPrimary, TextL, TextM, TextS } from 'app/components';
import { formatCPF, formatPhone } from 'app/helpers';
import { CustomRadioInput } from 'celcoin-design-system';
import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ApplicationRequestContext } from '../ApplicationRequest';
import {
  InfoArea,
  SubscribersEditSideSheetBtnArea,
  SubscribersEditSideSheetListItemAction,
  SubscribersEditSideSheetListItemInfo,
} from './styles';
import { ESubscriberType, TSubscriber } from './SubscribersAddNewSideSheet';
import { SubscriberInfoDivider } from './SubscribersStep';

interface ISubscribersEditSideSheetProps {
  handleClose: () => void;
  isOpen: boolean;
  subscriberItem: Partial<TSubscriber>;
}

const SubscribersEditSideSheet = ({
  isOpen,
  handleClose,
  subscriberItem,
}: ISubscribersEditSideSheetProps) => {
  const theme = useTheme();
  const { subscriber } = subscriberItem;
  const { subscribers, updateSubscribers } = useContext(
    ApplicationRequestContext,
  );
  const [subscriberType, updateSubscriberType] = useState<ESubscriberType>(
    subscriberItem?.subscriberType || ESubscriberType.COSIGNERS,
  );

  useEffect(() => {
    if (subscriberItem?.subscriberType) {
      updateSubscriberType(subscriberItem.subscriberType);
    }
  }, [subscriberItem]);

  const handleRemoveSubscriber = useCallback(() => {
    if (subscriberItem) {
      updateSubscribers(
        subscribers?.filter((sub) => sub.subscriber.id !== subscriber?.id) ||
          null,
      );
      setTimeout(() => handleClose());
    }
  }, [subscribers, subscriber]);

  const handleSaveSubscriber = useCallback(() => {
    if (subscriberItem) {
      updateSubscribers(
        subscribers?.map((sub) => {
          if (sub.subscriber.id === subscriber?.id) {
            return {
              ...sub,
              subscriberType,
            };
          }
          return sub;
        }) || null,
      );
      setTimeout(() => handleClose());
    }
  }, [subscribers, subscriberType, subscriber]);

  return (
    <ProductSideSheet
      open={isOpen}
      handleClose={handleClose}
      title="Adicionar na lista"
    >
      <SubscribersEditSideSheetListItemInfo>
        <TextL weight="semibold">
          {/* {subscriberPersonType === 'PERSON'
            ? subscriber?.full_name
            : subscriber?.legal_name} */}
          {subscriber?.full_name}
        </TextL>
        <InfoArea>
          <TextS>
            {/* {subscriberPersonType === 'PERSON'
              ? formatCPF(subscriber?.taxpayer_id || '')
              : formatCNPJ(subscriber?.taxpayer_id || '')} */}

            {formatCPF(subscriber?.taxpayer_id || '')}
          </TextS>
          <SubscriberInfoDivider />
          <TextS>
            {formatPhone(
              (subscriber?.phone.area_code || '') + subscriber?.phone.number ||
                '',
            )}
          </TextS>
        </InfoArea>
        <TextS>{subscriber?.email_address}</TextS>
      </SubscribersEditSideSheetListItemInfo>

      <SubscribersEditSideSheetListItemAction>
        <div className="relationship-area">
          <TextL weight="semibold">Função</TextL>
          <div className="relationship-area-items">
            <div
              className="relationship-area-item"
              onClick={() => updateSubscriberType(ESubscriberType.COSIGNERS)}
            >
              <CustomRadioInput
                isChecked={subscriberType === ESubscriberType.COSIGNERS}
              />
              <TextL>Anuente</TextL>
            </div>
            <div
              className="relationship-area-item"
              onClick={() => updateSubscriberType(ESubscriberType.GUARANTORS)}
            >
              <CustomRadioInput
                isChecked={subscriberType === ESubscriberType.GUARANTORS}
              />
              <TextL>Avalista</TextL>
            </div>
          </div>
        </div>
        <Divider />
        <div className="buttons-area">
          <ButtonPrimary
            style={{ width: 'fit-content' }}
            typeVariant="ghost"
            onClick={() => {
              handleRemoveSubscriber();
            }}
          >
            <TextM
              weight="Semibold"
              style={{ color: theme.palette.brand.error[900] }}
            >
              Remover Assinante
            </TextM>
          </ButtonPrimary>
        </div>
      </SubscribersEditSideSheetListItemAction>

      <SubscribersEditSideSheetBtnArea>
        <ButtonPrimary
          style={{
            width: 'fit-content',
            alignSelf: 'flex-end',
            marginRight: '15px',
          }}
          typeVariant="outline"
          onClick={handleClose}
        >
          Cancelar
        </ButtonPrimary>
        <ButtonPrimary
          style={{ width: 'fit-content', alignSelf: 'flex-end' }}
          typeVariant="fill"
          onClick={handleSaveSubscriber}
        >
          Salvar
        </ButtonPrimary>
      </SubscribersEditSideSheetBtnArea>
    </ProductSideSheet>
  );
};

export default SubscribersEditSideSheet;
