import CustomTableSelect, {
  TCustomSelectOption,
} from 'app/components/CustomTableSelect/CustomTableSelect';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { Heading3 } from '../../../../../../../app/components';
import { formatDateFromBackend } from '../../../../../../../app/helpers/dateHelpers';
import { TQualification } from '../../../../../context';
import { EQualificationRole } from '../../../../../context/QualificationsProvider/qualifications.interfaces';
import {
  BusinessDetailsHeader,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from '../styles';

const QualificationRoleDescription = {
  [EQualificationRole.EMPLOYER]: 'Empregador',
};

export interface IBusinessDetailsSideSheetBusiness {
  qualification: TQualification;
  handleUpdateQualificationStatus: (
    qualificationId: string,
    identifier: string,
  ) => void;
  statusList: TCustomSelectOption[];
  isStatusLoading: boolean;
}

const BusinessDetailsSideSheetBusiness = ({
  qualification,
  statusList,
  isStatusLoading,
  handleUpdateQualificationStatus,
}: IBusinessDetailsSideSheetBusiness) => {
  const { isProfileAuthorized } = usePermify();
  return (
    <>
      <BusinessDetailsHeader>
        <Heading3>Empresa</Heading3>
      </BusinessDetailsHeader>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <strong>Situação</strong>
              <span>
                Ultima atualização:{' '}
                {formatDateFromBackend(
                  qualification.created_at,
                ).toLocaleDateString()}
              </span>
            </span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <CustomTableSelect
                selectItems={statusList}
                isLoading={isStatusLoading}
                disabled={
                  !isProfileAuthorized(EAccountPermissions.UPDATE_QUALIFICATION)
                }
                handleChange={(identifier) =>
                  handleUpdateQualificationStatus(qualification.id, identifier)
                }
                value={qualification.status}
              />
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <strong>Função</strong>
            </span>
            <span>{QualificationRoleDescription[qualification.role]}</span>
          </ViewBoxItem>
        </ViewBoxContent>
      </ViewBoxContainer>
    </>
  );
};

export default BusinessDetailsSideSheetBusiness;
