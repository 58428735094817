import { useState, useEffect, useCallback } from 'react';
import {
  BoxSection,
  ButtonDefault,
  ConfirmModal,
} from '../../../../../../app/components';
import { TRegisterBusinessDataRequest } from '../../../../context';
import { useTheme } from '@mui/material';
import {
  ButtonArea,
  LegalPersonViewScrDetailsHeader,
  LegalPersonViewScrListContent,
  TableWrapper,
} from './styles';
import { Heading3, Table, TextM, TextS } from 'celcoin-design-system';
import { EmptyArea } from 'modules/management/components';
import { useCustomerService } from 'modules/customer/services';
import { formatCNPJ } from 'app/helpers';
import {
  TScrDetailItem,
  TScrListResponse,
  TScrResponse,
} from 'modules/customer/interfaces/scr';
import { AddOutlined } from '@mui/icons-material';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { formatCurrency } from 'app/utils/normalizer';
import NaturalPersonViewScrDetailsSideSheet from './LegalPersonViewScrDetailsSideSheet';
import { useNavigate } from 'react-router-dom';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { ExternalIcon } from 'app/components/Icons';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import {
  formatDateFromBackend,
  formatStringHourFromBackend,
} from 'app/helpers/dateHelpers';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';

type ILegalPersonViewScrTabProps = {
  business: TRegisterBusinessDataRequest;
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextS weight="Bold">Data base consultada</TextS>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextS weight="Bold">Data da consulta</TextS>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextS weight="Bold">Coobrigação assumida</TextS>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextS weight="Bold">Ação</TextS>
      </TableHeadCellContainer>
    ),
  },
];

const LegalPersonViewScrTab = ({ business }: ILegalPersonViewScrTabProps) => {
  const theme = useTheme();
  const [isConfirmRequestScrOpen, toggleConfirmRequestModalOpen] =
    useState(false);
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const [scrDetailItem, updateScrDetailItem] = useState<TScrDetailItem | null>(
    null,
  );
  const [scrList, updateScrList] = useState<TScrListResponse[]>();
  const {
    getBusinessScrList,
    requestBusinessScr,
    getBusinessScrLoading,
    requestBusinessScrLoading,
  } = useCustomerService();
  const navigate = useNavigate();
  const { isProfileAuthorized } = usePermify();

  const fetchScrList = (page: number, itemsPerPage?: number) => {
    getBusinessScrList(business.id as string, page, itemsPerPage).then(
      (scrListResponse) => {
        if (scrListResponse) {
          updateScrList(
            scrListResponse.content.map((scr) => ({
              created_at: scr.created_at,
              id: scr.id,
              requested_date: scr.requested_date,
              result: JSON.parse(scr.result) as TScrResponse,
            })),
          );
          setTotalItemsQty(scrListResponse.total_elements);
        }
      },
    );
  };

  useEffect(() => {
    if (isProfileAuthorized(EAccountPermissions.READ_SCR)) {
      fetchScrList(0);
    } else {
      navigate('/');
    }
  }, [business]);

  const onRequestScr = useCallback(() => {
    requestBusinessScr(business.id as string).then((scrResponse) => {
      if (scrResponse) fetchScrList(0);
      toggleConfirmRequestModalOpen(false);
    });
  }, [business]);

  return (
    <BoxSection>
      <LegalPersonViewScrDetailsHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: 'fit-content',
          }}
        >
          <Heading3
            style={{ color: theme.palette.brand.secondary.base, width: '100%' }}
          >
            SCR
          </Heading3>
          <ButtonArea style={{ marginBottom: '0px' }}>
            {isProfileAuthorized(EAccountPermissions.CREATE_SCR) && (
              <ButtonDefault
                style={{ maxWidth: '136px', height: '48px', padding: '12px' }}
                onClick={() => toggleConfirmRequestModalOpen(true)}
              >
                <AddOutlined
                  style={{
                    color: theme.palette.brand.primary.base as string,
                    marginRight: '5px',
                    fontSize: '22px',
                  }}
                />
                <TextM
                  weight="Semibold"
                  style={{ color: theme.palette.brand.primary.base }}
                >
                  Gerar SCR
                </TextM>
              </ButtonDefault>
            )}
          </ButtonArea>
        </div>
      </LegalPersonViewScrDetailsHeader>
      <LegalPersonViewScrListContent>
        {scrList && scrList.length > 0 ? (
          <>
            <TableWrapper>
              <Table
                tableHeadCell={tableHeader}
                showPagination
                handlePageChange={(selectedPage) => {
                  setCurrentPage(selectedPage - 1);
                  fetchScrList(selectedPage - 1, linesPerPage);
                }}
                handleLinesPerPage={(selectedLinesPerPage) => {
                  setCurrentPage(0);
                  setLinesPerPage(
                    selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                  );
                }}
                totalElementsQty={totalItemsQty}
                currentPage={currentPage + 1}
                linesPerPage={linesPerPage}
                tableBodyContent={scrList.map((scr, index) => {
                  const baseDateString =
                    scr.result.dados.dataBaseConsultada?.split('-');
                  const baseDate = baseDateString
                    ? new Date(
                        parseInt(baseDateString[0]),
                        parseInt(baseDateString[1]) - 1,
                        1,
                      )
                    : new Date();
                  return {
                    id: `${scr.result.dados.dataBaseConsultada}-${String(
                      index,
                    )}`,
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Regular">
                              {`${format(baseDate, 'MMM', {
                                locale: ptBR,
                              }).toUpperCase()} ${format(baseDate, 'yyyy', {
                                locale: ptBR,
                              })}`}
                              {}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>{`${formatDateFromBackend(
                              scr?.created_at || '',
                            ).toLocaleDateString('pt-BR')} às
                              ${formatStringHourFromBackend(
                                scr?.created_at || '',
                              )}`}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {scr.result?.dados?.coobrigacaoAssumida
                                ? formatCurrency(
                                    parseFloat(
                                      scr.result.dados.coobrigacaoAssumida,
                                    ),
                                  )
                                : '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            <Link
                              to={`/records/legal/${business.id}/scr/${scr.id}`}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '14px',
                                color: theme.palette.brand.primary
                                  .base as string,
                                textDecoration: 'underline',
                              }}
                            >
                              Acessar
                              <span
                                style={{
                                  paddingLeft: '10px',
                                }}
                              >
                                <ExternalIcon
                                  color={
                                    theme.palette.brand.primary.base as string
                                  }
                                  width={9}
                                  height={9}
                                />
                              </span>
                            </Link>
                          </TableBodyCellContainer>
                        ),
                      },
                    ],
                  };
                })}
              />
            </TableWrapper>
          </>
        ) : (
          <EmptyArea
            message="Nenhuma consulta de SCR realizada"
            createButtonText="Realizar consulta"
            onCreateClick={
              isProfileAuthorized(EAccountPermissions.CREATE_SCR)
                ? () => toggleConfirmRequestModalOpen(true)
                : undefined
            }
            isLoading={getBusinessScrLoading}
          >
            {null}
          </EmptyArea>
        )}
      </LegalPersonViewScrListContent>
      <ConfirmModal
        isOpen={isConfirmRequestScrOpen}
        title="Essa consulta é cobrada ao Originador. Deseja prosseguir?"
        color="primary"
        btnConfirmText="Confirmar"
        isLoading={requestBusinessScrLoading}
        handleConfirm={onRequestScr}
        handleClose={() => toggleConfirmRequestModalOpen(false)}
        handleCancel={() => toggleConfirmRequestModalOpen(false)}
      >
        Consultas ao SCR precisam do consentimento Livre, Informado e Inambíguo
        da Empresa. Você confirma que obteve consentimento do titular do CNPJ:{' '}
        <strong>{formatCNPJ(business.taxpayer_id)}</strong>?
      </ConfirmModal>
      <NaturalPersonViewScrDetailsSideSheet
        handleClose={() => updateScrDetailItem(null)}
        isOpen={scrDetailItem !== null}
        itemDetail={scrDetailItem}
      />
    </BoxSection>
  );
};

export default LegalPersonViewScrTab;
