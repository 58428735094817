import styled from '@emotion/styled';

export const ReviewStepContent = styled.div``;

export const ReviewStepContainer = styled.div`
  margin-bottom: 32px;
`;

export const ReviewStepBorrowerBox = styled.div`
  width: 100%;
  margin-top: 40px;
`;

export const ReviewStepBox = styled.div<{
  marginTop: string;
  marginBottom: string;
  padding?: string;
}>`
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  padding:  ${({ padding }) =>
    typeof padding === 'string' ? padding : '40px'};
  border-radius: 20px;
  margin: ${({ marginTop }) => marginTop} 0px ${({ marginBottom }) =>
  marginBottom} 0px;

  .MuiAccordion-root {
    background-color: transparent !important;
  }
`;

export const ReviewSimulationBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
`;

export const ReviewSimulationPreviewItems = styled.div`
  width: 50%;
  &:first-of-type {
    padding-right: 20px;
  }
  &:nth-of-type(2) {
    padding-left: 20px;
  }
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 20px;
  }
`;

export const ReviewSimulationPreviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
`;

export const SignTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .bullet-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bullet-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding-left: 15px;
  }
  
`;

export const TedModalContent = styled.div`
  text-align: center;
  span {
    display: block;
  }

  .link {
    display: block;
    font-size: 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    font-weight: bold;
    margin-top: 8px;
  }
`;
