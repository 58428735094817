import { useState } from 'react';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { RatingHttpService } from '../api/ratingHttpService';
import { IRatings } from './intefaces';

const useRatingsService = () => {
  const [getRatingsLoading, setGetRatingsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const getRatings = async (
    productId: string,
    fundingId: string,
  ): Promise<IRatings[] | undefined> => {
    setGetRatingsLoading(true);
    try {
      const qualificationsService = new RatingHttpService();
      const res = await qualificationsService.getRatings(productId, fundingId);
      return res.data;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados de ratings');
    } finally {
      setGetRatingsLoading(false);
    }
  };

  return {
    getRatingsLoading,
    getRatings,
  };
};

export default useRatingsService;
