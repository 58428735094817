/* eslint-disable @typescript-eslint/no-explicit-any */
import { TCustomValue } from 'celcoin-design-system';
import * as yup from 'yup';

export const editConditionValidation = yup.object({
  creditLimit: yup.string(),
  maxPaymentAmount: yup.string(),
  minRequestedAmount: yup.string(),
  minGracePeriod: yup.string(),
  maxGracePeriod: yup.string(),
  dueDay: yup.string().test({
    name: 'dueDay field',
    test: (value: unknown, { createError }) => {
      return Number((value as string)?.replace(',', '.')) <= 0 ||
        Number((value as string)?.replace(',', '.')) > 31
        ? createError({
            message: 'Insira um valor válido',
            path: 'dueDay',
          })
        : true;
    },
  }),
  minPaymentNumber: yup.string(),
  maxPaymentNumber: yup.string().when('interestRate', {
    is: (val: any) => val,
    then: () => yup.string().required('Campo obrigatório'),
  }),
  tac: yup.string(),
  interestRate: yup.string(),
  rating: yup
    .object({
      label: yup.string().required('Insira um valor'),
      value: yup.string().required('Insira um valor'),
    })
    .typeError('Insira um valor')
    .test({
      name: 'rating field',
      test: (value: unknown, { createError }: { createError: any }) => {
        return Object.keys(value as TCustomValue).length === 0 ||
          Object.values(value as TCustomValue).find(
            (item) => item === 'Selecionar',
          )
          ? createError({
              message: 'Insira um valor',
              path: 'rating',
            })
          : true;
      },
    }),
});

export const editConditionValidationRequired = yup.object({
  creditLimit: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'creditLimit field',
      test: (value: unknown, { createError }) => {
        return Number((value as string)?.replace(',', '.')) === 0
          ? createError({
              message: 'Insira um valor',
              path: 'creditLimit',
            })
          : true;
      },
    }),
  maxPaymentAmount: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'maxPaymentAmount field',
      test: (value: unknown, { createError }) => {
        return Number((value as string)?.replace(',', '.')) === 0
          ? createError({
              message: 'Insira um valor',
              path: 'maxPaymentAmount',
            })
          : true;
      },
    }),
  minRequestedAmount: yup.string(),
  minGracePeriod: yup.string(),
  maxGracePeriod: yup.string(),
  dueDay: yup.string().test({
    name: 'dueDay field',
    test: (value: unknown, { createError }) => {
      return Number((value as string)?.replace(',', '.')) <= 0 ||
        Number((value as string)?.replace(',', '.')) > 31
        ? createError({
            message: 'Insira um valor válido',
            path: 'dueDay',
          })
        : true;
    },
  }),
  minPaymentNumber: yup.string().required('Campo obrigatório'),
  maxPaymentNumber: yup.string().required('Campo obrigatório'),
  tac: yup.string(),
  interestRate: yup.string().required('Campo obrigatório'),
  rating: yup
    .object({
      label: yup.string().required('Insira um valor'),
      value: yup.string(),
    })
    .typeError('Insira um valor')
    .test({
      name: 'rating field',
      test: (value: unknown, { createError }: { createError: any }) => {
        return Object.keys(value as TCustomValue).length === 0 ||
          Object.values(value as TCustomValue).find(
            (item) => item === 'Selecionar',
          )
          ? createError({
              message: 'Insira um valor',
              path: 'rating',
            })
          : true;
      },
    }),
});
