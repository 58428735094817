/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import {
  CustomSelectContainer,
  CustomSelectItem,
  CustomSelectItemContainer,
  InputContent,
  InputLabelContainer,
  LineItem,
  TextFieldArea,
} from './styles';
import { InputError, TextM } from '../typography';
import { Divider, useTheme } from '@mui/material';
import { ChevronDownIcon } from '../Icons';
import { Popover } from 'celcoin-design-system';

export type TCustomValue = {
  label: string;
  value: string;
};

export interface ICustomSelectModalProps {
  isSelectOpen: boolean;
  labelValue?: string;
  value?: TCustomValue;
  options?: TCustomValue[];
  validationError?: string;
  placeholder?: string;
  name?: string;
  handleSelectClose: () => void;
  handleSelectChange: ({
    value,
    label,
  }: {
    value: string;
    label: string;
  }) => void;
  handleClick: () => void;
}

const CustomDivider = () => {
  return (
    <div>
      <Divider sx={{ height: '100%', width: '100%' }} />
    </div>
  );
};

const CustomSelectModal = ({
  isSelectOpen,
  labelValue,
  value,
  validationError,
  options,
  placeholder,
  handleClick,
  handleSelectChange,
  handleSelectClose,
}: ICustomSelectModalProps) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  return (
    <>
      <CustomSelectContainer ref={inputRef} onClick={handleClick}>
        {labelValue && <InputLabelContainer>{labelValue}</InputLabelContainer>}
        <TextFieldArea>
          <LineItem />
          <InputContent>
            <div className="input-area">
              {value?.label ? (
                <TextM
                  style={{ color: theme.palette.brand.primary.base }}
                  weight="Medium"
                >
                  {value.label}
                </TextM>
              ) : (
                placeholder
              )}
            </div>
            <div className="input-icon">
              <ChevronDownIcon
                color={theme.palette.brand.primary.base as string}
              />
            </div>
          </InputContent>
        </TextFieldArea>
        {validationError && <InputError>{validationError}</InputError>}
      </CustomSelectContainer>
      <Popover
        open={isSelectOpen}
        anchorEl={inputRef.current}
        onClose={handleSelectClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper	': {
            marginTop: '8px',
            marginLeft: '0px',
            minWidth: '160px',
            boxShadow: '0px 18px 25px rgba(0, 21, 33, 0.14)',
            backgroundColor: '#FDFDFD',
            border: '1px solid #EDEDED',
            borderRadius: '20px',
          },
        }}
      >
        <CustomSelectItemContainer>
          {options?.map((item) => (
            <>
              <CustomSelectItem
                key={item.label}
                style={{ width: (inputRef.current?.offsetWidth || 300) - 50 }}
                onClick={() => {
                  handleSelectChange(item);
                  handleSelectClose();
                }}
              >
                <TextM weight="Medium">{item.label}</TextM>
              </CustomSelectItem>
              <CustomDivider />
            </>
          ))}
        </CustomSelectItemContainer>
      </Popover>
    </>
  );
};

export default CustomSelectModal;
