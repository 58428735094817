import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import {
  TPreviewSimulationData,
  TSimulatorParametersData,
} from 'app/components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';
import { useBreadcrumbsContext } from 'app/context';
import { IApplicationCustomVariablesValues } from 'app/interfaces/customVariablesInterfaces';
import { Stepper } from 'celcoin-design-system';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import { TProducts } from 'modules/products/context';
import {
  createContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { BorrowerStep } from './BorrowerStep';
import { ProductStep } from './ProductStep';
import { ReviewStep } from './ReviewStep';
import { SimulationStep } from './SimulationStep';
import {
  ApplicationRequestContainer,
  ApplicationRequestStepContainer,
  ApplicationRequestStepperContainer,
} from './styles';
import { SubscribersStep } from './SubscribersStep';
import {
  ESubscriberType,
  TSubscriber,
} from './SubscribersStep/SubscribersAddNewSideSheet';
import { VariablesStep } from './VariablesStep';
import { useLocation } from 'react-router-dom';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import {
  useApplicationsService,
  useProductsService,
} from 'modules/products/services/hooks';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import {
  simulatorActionTypes,
  useSimulatorContext,
} from 'modules/simulation/context';
import { TQualification } from 'modules/customer/interfaces/qualifications';
import { useAuthContext } from 'modules/auth/context';
import { FundingStep } from './FundingStep';
import { TBeneficiaryAccount } from 'modules/products/interfaces/beneficiaryAccount';
import { BeneficiaryAccountStep } from './BeneficiaryAccountStep';

const stepsComponent = [
  {
    title: 'Funding',
    component: <FundingStep />,
  },
  {
    title: 'Produto',
    component: <ProductStep />,
  },
  {
    title: 'Tomador',
    component: <BorrowerStep />,
  },
  {
    title: 'Conta Beneficiária',
    component: <BeneficiaryAccountStep />,
  },
  {
    title: 'Valores da Solicitação',
    component: <SimulationStep />,
  },
  {
    title: 'Anuentes e Avalistas',
    component: <SubscribersStep />,
  },
  {
    title: 'Campos adicionais',
    component: <VariablesStep />,
  },
  {
    title: 'Ficou tudo certo?',
    component: <ReviewStep />,
  },
  {
    title: 'Assinatura',
  },
];

type IApplicationRequestContext = {
  activeStep: number;
  onForward: () => void;
  onBack: () => void;
  onBackTwoSteps: () => void;

  hasCustomVariables?: boolean;
  updateHasCustomVariables: (hasCustomVariables: boolean) => void;

  borrowerData:
    | (TRegisterPersonDataRequest & TRegisterBusinessDataRequest)
    | null;
  updateBorrowerData: (
    borrower:
      | (TRegisterPersonDataRequest & TRegisterBusinessDataRequest)
      | null,
  ) => void;

  borrowerQualification: TQualification | null;
  updateBorrowerQualification: (qualification: TQualification | null) => void;

  subscribers: Array<TSubscriber> | null;
  updateSubscribers: (subscribers: Array<TSubscriber> | null) => void;

  product: TProducts | null;
  updateProduct: (product: TProducts) => void;

  funding: string | null;
  hasMultiplesFundings: boolean;
  updateFunding: (funding: string) => void;

  beneficiaryAccount: TBeneficiaryAccount | null;
  updateBeneficiaryAccount: (beneficiaryAccount: TBeneficiaryAccount) => void;

  customVariables: IApplicationCustomVariablesValues[] | null;
  updateCustomVariables: (
    variables: IApplicationCustomVariablesValues[],
  ) => void;

  basePremiumAmount: number | null;
  simulation: TPreviewSimulationData | null;
  simulationRequest: TSimulatorParametersData | null;
  updateSimulation: (
    simulation: TPreviewSimulationData,
    simulationRequest: TSimulatorParametersData,
  ) => void;

  isClonning: TApplicationResponse | null;
};

export const ApplicationRequestContext =
  createContext<IApplicationRequestContext>({
    activeStep: 1,
    hasCustomVariables: false,
    isClonning: null,

    updateHasCustomVariables: () => {
      throw new Error('Método não implementado');
    },

    onForward: () => {
      throw new Error('Método não implementado');
    },
    onBack: () => {
      throw new Error('Método não implementado');
    },
    onBackTwoSteps: () => {
      throw new Error('Método não implementado');
    },

    beneficiaryAccount: null,
    updateBeneficiaryAccount: () => {
      throw new Error('Método não implementado');
    },

    funding: null,
    hasMultiplesFundings: false,
    updateFunding: () => {
      throw new Error('Método não implementado');
    },

    product: null,
    updateProduct: () => {
      throw new Error('Método não implementado');
    },

    customVariables: null,
    updateCustomVariables: () => {
      throw new Error('Método não implementado');
    },

    borrowerData: null,
    updateBorrowerData: () => {
      throw new Error('Método não implementado');
    },

    borrowerQualification: null,
    updateBorrowerQualification: () => {
      throw new Error('Método não implementado');
    },

    subscribers: null,
    updateSubscribers: () => {
      throw new Error('Método não implementado');
    },

    simulation: null,
    basePremiumAmount: null,
    simulationRequest: null,
    updateSimulation: () => {
      throw new Error('Método não implementado');
    },
  });

export enum TacValueType {
  PERCENTAGE = 'PERCENTAGE',
  MONETARY = 'MONETARY',
}

const ApplicationRequest = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const [activeStep, updateActiveStep] = useState<number>(0);
  const [product, updateProduct] = useState<TProducts | null>(null);
  const [hasCustomVariables, updateHasCustomVariables] = useState(false);
  const [isClonning, updateIsClonning] = useState<TApplicationResponse | null>(
    null,
  );
  const [beneficiaryAccount, updateBeneficiaryAccount] =
    useState<TBeneficiaryAccount | null>(null);
  const { getPerson } = usePersonService();
  const { getBusiness } = useCustomerService();
  const { getProduct } = useProductsService();
  const [borrowerData, updateBorrowerData] = useState<
    (TRegisterPersonDataRequest & TRegisterBusinessDataRequest) | null
  >(null);
  const [simulation, updateSimulation] =
    useState<TPreviewSimulationData | null>(null);
  const [borrowerQualification, updateBorrowerQualification] =
    useState<TQualification | null>(null);
  const [simulationRequest, updateSimulationRequest] =
    useState<TSimulatorParametersData | null>(null);
  const [subscribers, updateSubscribers] = useState<Array<TSubscriber> | null>(
    null,
  );
  const [basePremiumAmount, updateBasePremiumAmount] = useState<number | null>(
    null,
  );
  const { getBasePremiumAmount } = useApplicationsService();
  const { dispatch } = useSimulatorContext();
  const [customVariables, updateCustomVariables] = useState<
    IApplicationCustomVariablesValues[] | null
  >(null);
  const [selectedFunding, updateSelectedFunding] = useState<string | null>(
    null,
  );
  const { userInfo } = useAuthContext();
  const location = useLocation();

  const hasMultiplesFundings = useMemo(
    () => userInfo && userInfo.fundings.length > 1,
    [userInfo],
  );

  const hasBorrower = useMemo(() => {
    if (typeof location.state?.person === 'object') {
      history.replaceState({}, document.title);
      return location.state?.person;
    }
    if (typeof location.state?.business === 'object') {
      history.replaceState({}, document.title);
      return location.state?.business;
    }
    return null;
  }, [location]);

  const hasApplication = useMemo<TApplicationResponse | null>(() => {
    if (typeof location.state?.application === 'object') {
      history.replaceState({}, document.title);
      return location.state?.application;
    }
    return null;
  }, [location]);

  const hasCreatedBorrower = useMemo(() => {
    if (typeof location.state?.borrower === 'object') {
      history.replaceState({}, document.title);
      return {
        product: location.state?.product,
        borrower: location.state?.borrower,
      };
    }
    return null;
  }, [location]);

  const onForward = useCallback(() => {
    if (activeStep < steps.length) {
      updateActiveStep(activeStep + 1);
    }
  }, [activeStep, hasBorrower]);

  const onBackTwoSteps = useCallback(() => {
    if (activeStep > 1) {
      updateActiveStep(activeStep - 2);
    }
  }, [activeStep]);

  const onBack = useCallback(() => {
    if (activeStep > 0) {
      updateActiveStep(activeStep - 1);
    }
  }, [activeStep]);

  useEffect(() => {
    setItems(
      [
        { to: '/products/main', label: 'Produtos' },
        { to: '/products/application', label: 'Criar solicitação' },
      ],
      <AccountBalanceOutlined />,
    );

    return () => resetBreadcrumbs();
  }, []);

  useEffect(() => {
    if (hasCreatedBorrower !== null) {
      if (
        typeof hasCreatedBorrower.product === 'object' &&
        typeof hasCreatedBorrower.borrower === 'object'
      ) {
        updateBorrowerData(hasCreatedBorrower.borrower);
        updateProduct(hasCreatedBorrower.product);
        updateActiveStep(2);
      }
    }
  }, [hasCreatedBorrower]);

  useEffect(() => {
    if (typeof hasBorrower === 'object' && hasBorrower !== null) {
      updateBorrowerData(hasBorrower);
    }
  }, [hasBorrower]);

  useEffect(() => {
    if (hasApplication !== null) {
      updateIsClonning(hasApplication);
      getProduct(hasApplication.product.id).then((productDetails) => {
        const productData = productDetails?.data as TProducts;
        updateProduct(productData);

        if (
          productData.beneficiary_type === 'EXTERNAL_REGISTERED_ACCOUNT' &&
          hasApplication.beneficiary_account
        ) {
          updateBeneficiaryAccount(() => hasApplication!.beneficiary_account!);
        }
        if (productData.borrower_type === 'PERSON') {
          getPerson(hasApplication.borrower.id).then((personDetails) => {
            updateBorrowerData(
              personDetails as TRegisterPersonDataRequest &
                TRegisterBusinessDataRequest,
            );
          });
        } else {
          getBusiness(hasApplication.borrower.id).then((personDetails) => {
            updateBorrowerData(
              personDetails as TRegisterPersonDataRequest &
                TRegisterBusinessDataRequest,
            );
          });
        }
      });

      if (hasApplication.funding?.id) {
        updateSelectedFunding(() => hasApplication.funding!.id!);
      }

      dispatch({
        type: simulatorActionTypes.SET_SIMULATOR_DATA,
        payload: {
          amount: hasApplication.requested_amount,
          financeFee: hasApplication.finance_fee,
          firstPaymentDate: hasApplication.first_payment_date,
          interestRate: hasApplication.interest_rate,
          numPayments: hasApplication.num_payments,
          disbursementDate: hasApplication.disbursement_date,
          tacAmount: hasApplication.tac_amount,
          amountKey: '0',
        },
      });
      Promise.all(
        hasApplication.co_signers.map<Promise<TSubscriber>>(
          async (coSigner) => {
            if (coSigner.type === 'PERSON') {
              const coSignerDetails = await getPerson(coSigner.co_signer_id);
              return {
                subscriber: coSignerDetails as TRegisterPersonDataRequest &
                  TRegisterBusinessDataRequest,
                subscriberPersonType: 'PERSON',
                subscriberType: ESubscriberType.COSIGNERS,
              };
            }
            const coSignerDetails = await getBusiness(coSigner.co_signer_id);
            return {
              subscriber: coSignerDetails as TRegisterPersonDataRequest &
                TRegisterBusinessDataRequest,
              subscriberPersonType: 'BUSINESS',
              subscriberType: ESubscriberType.COSIGNERS,
            };
          },
        ),
      ).then((coSigners) => {
        Promise.all(
          hasApplication.guarantors.map<Promise<TSubscriber>>(
            async (guarantor) => {
              if (guarantor.type === 'PERSON') {
                const guarantorDetails = await getPerson(
                  guarantor.guarantor_id,
                );
                return {
                  subscriber: guarantorDetails as TRegisterPersonDataRequest &
                    TRegisterBusinessDataRequest,
                  subscriberPersonType: 'PERSON',
                  subscriberType: ESubscriberType.GUARANTORS,
                };
              }
              const guarantorDetails = await getBusiness(
                guarantor.guarantor_id,
              );
              return {
                subscriber: guarantorDetails as TRegisterPersonDataRequest &
                  TRegisterBusinessDataRequest,
                subscriberPersonType: 'BUSINESS',
                subscriberType: ESubscriberType.GUARANTORS,
              };
            },
          ),
        ).then((guarantors) => {
          updateSubscribers(
            ([] as TSubscriber[]).concat(coSigners, guarantors),
          );
        });
      });

      return () => {
        dispatch({
          type: simulatorActionTypes.RESET_SIMULATOR_DATA,
        });
      };
    }
  }, [hasApplication]);

  useEffect(() => {
    if (!hasMultiplesFundings && !hasApplication && userInfo) {
      updateSelectedFunding(userInfo.fundings[0]);
    }
  }, [hasMultiplesFundings]);

  const steps = useMemo(() => {
    const stepsList = stepsComponent.filter((step) => {
      if (step.title === 'Funding' && !hasMultiplesFundings) return false;
      if (
        step.title === 'Conta Beneficiária' &&
        product?.beneficiary_type !== 'EXTERNAL_REGISTERED_ACCOUNT'
      )
        return false;
      return true;
    });
    return stepsList;
  }, [product, hasMultiplesFundings]);

  const renderSteps = () => {
    return steps[activeStep].component;
  };

  return (
    <ApplicationRequestContext.Provider
      value={{
        activeStep,
        onBack,
        onBackTwoSteps,
        onForward,

        hasCustomVariables,
        updateHasCustomVariables,

        borrowerData,
        updateBorrowerData,

        product,
        updateProduct,

        customVariables,
        updateCustomVariables,

        subscribers,
        updateSubscribers,

        borrowerQualification,
        updateBorrowerQualification,

        beneficiaryAccount,
        updateBeneficiaryAccount,

        funding: selectedFunding,
        hasMultiplesFundings: !!hasMultiplesFundings,
        updateFunding: updateSelectedFunding,

        basePremiumAmount,
        simulation,
        simulationRequest,
        updateSimulation: (s, sr) => {
          updateSimulation(s);
          updateSimulationRequest(sr);
          if (s.financed_amount && selectedFunding) {
            getBasePremiumAmount(s.financed_amount, selectedFunding).then(
              (res) => updateBasePremiumAmount(res.amount),
            );
          }
        },

        isClonning,
      }}
    >
      <ApplicationRequestContainer>
        <ApplicationRequestStepperContainer>
          <Stepper
            activeStep={activeStep}
            stepsItems={steps.map((step, inx) => ({
              step: inx,
              title: step.title,
              clickable: false,
            }))}
          />
        </ApplicationRequestStepperContainer>
        <ApplicationRequestStepContainer>
          {renderSteps()}
        </ApplicationRequestStepContainer>
      </ApplicationRequestContainer>
    </ApplicationRequestContext.Provider>
  );
};

export default ApplicationRequest;
