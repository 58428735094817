import { BaseHttpService } from 'app/services';

export class RatingHttpService extends BaseHttpService {
  baseUrl = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  getRatings = async (productId: string, fundingId: string) => {
    this.setHeader({
      funding: fundingId,
    });
    const res = await this.get(
      `${this.baseUrl}/funding/products/${productId}/ratings`,
    );

    return res;
  };
}
